import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import TwoCentsIndex from './TwoCentsIndex'

export default function Health() {

    return (
        <Container>
            <Row>
                <TwoCentsIndex />

                <Col>
                    <h1>Health Tips</h1>
                    <p>These are random bits of wisdom picked up over the years. There are additional pages on Losing Weight, and Sore Knees in the Triathlon Training section which might be relevant.</p>
                    <p>I'm sure everyone has a similar list. In fact if you've got some tidbit you'd like to share, send me an e-mail, and if I think it's good advice, I'll tack it&nbsp;on at the end.</p>
                    <h3>Back Spasms</h3>
                    <p>Have you ever bent over to do something innocuous and suddenly had your back go into spasm? I've had it happen to me several times now. (When you're supposed to be some kind of body expert it's annoying to have to admit to this sort of thing.) While it seems to happen suddenly, it takes a while to set up one of these. Typically it's the result of a lot of slouching. If you lean back in a chair and there's no support under your lower back, it will round backwards. Do this long enough and consistently enough and the gel within the intervertebral disc  is slowly but surely pushed toward the back of the spine. At some point a critical moment arrives and your back goes into spasm to try and protect the spine from further abuse. The way out is to reverse the process. Do a lot of gently arching of the back. Avoid rounding the back. I can't advise you on this, but I take some ibuprofen. Research has shown that you'll get better faster if you try to stay active.&nbsp;And then just give it a few days to get back to normal. The better approach is to avoid the problem in the first place. Don't slouch. Use a back roll behind your lower&nbsp;spine if you tend to slouch when sitting.</p>
                    <h3>Burning Eyes</h3>
                    <p>Recently I went for about six months feeling tired all day. Eyes burning from lack of sleep. The odd thing was, I was getting my normal eight hours a night. I couldn't figure out what was wrong. Then&nbsp;at my annual checkup my doctor suggested I get an eye exam. I thought that was odd, my eyesight&nbsp;had never been an issue for me. But I'm mid 60s and the doc thought it would be a good idea. Fine. I went. My eyesight tested out AOK. But as the eye doctor summarized everything he said, <em>except for the dandruff, you're ... </em>Whoa! Back up the truck. Dandruff???<br></br>
                        "Yeah," he said, "You have dandruff on your eyelids. Do your eyes burn at all during the day?"<br></br>
                        "Yes!" I said this with a fair degree of amazement.<br></br>
                        "Well, the dandruff is causing it."<br></br>
                        I got some dandruff shampoo and made a point of gently washing my eyelids when I was shampooing. One time I wasn't careful and managed to get some in my eye. It was one of the most painful moments I've ever experienced. So you've got to be incredibly careful with this. As an alternative, you can buy pre-moistened wipes for your eyelids. Hot compresses over the eyelids can also help a little. But for me the best solution was Johnson &amp; Johnson's Baby Shampoo. (For what it's worth, my doctor warned me not to get a generic version, <em>get the Johnson Johnson verion</em>.) Every day when I'm in the shower I put some on a wash cloth and use that as a compress on my eyes.I have yet to experience eye burn with the baby shampoo and it really works for me. I also dunk my face in a sink full of warm water each morning and then wipe with a wash cloth.<br></br>
                        But the change was dramatic. My eyes weren't burning because I was tired. I felt tired because my eyes were burning. Once I got rid of the burning eyes, I started enjoying my days again. I felt energized and happy. What a difference.<br></br></p>
                    <h3>Biotin for Healthy Nails</h3>
                    <p>I'm a guitarist who uses nails for a sort of hybrid picking I do, so this is an important issue for me. When&nbsp;I was growing up the folklore wisdom was to supplement with gelatin for stronger nails. But they've actually done some research and discovered that gelatin does no good at all. Biotin (available as a pill supplement) actually will create stronger nails. If you want more advice there's a list of 14 tips put together by <a href="http://www.rd.com/health/14-tips-for-strong-and-healthy-nails/" target="_blank" rel="noopener noreferrer">Reader's Digest</a>. The only one which seems dated is the suggestion&nbsp;to only wear 100% cotton socks. Modern sports socks do a much better job of transferring moisture away from the skin than cotton does.</p>
                    <h3>Flossing</h3>
                    <p>Flossing keeps your gums healthy. I didn't use to floss. Now I do. My dentist can tell the difference. Flossing also cuts down on the incidence of infection. When I first read that I found it a perplexing claim. What the heck were they talking about? Well, your life expectancy goes up by a few years if you floss. People actually die from infections that began in their mouths, and there's a reduced incidence of heart disease. If you don't enjoy dealing with floss&nbsp;(I don't), you can buy floss picks. I think part of my initial resistance was that I didn't know how to floss properly. You don't simply stick the floss between your teeth and pull it out. Gently&nbsp;push it down toward the bass of the tooth so you go a shade below the gum line then slide the floss upward as you hold it against the side of the tooth. Since there&nbsp;are two teeth for every gap, you need to do each gap twice, once for the forward tooth and once for the opposite tooth.</p>
                    <h3>Bad Breath</h3>
                    <p>First of all, brush your teeth. But you can also create a coating on your tongue, especially if you're a mouth breather, that can contribute to the problem. Get a tongue scraper and make that part of you regimen.</p>
                    <h3>Hair Loss</h3>
                    <p>You know how great it is to stand under a really hot shower and let it drill down to your scalp? Evidently this also has the added benefit of allowing you to lose lots of hair. The heat opens up the hair follicle and allows the hair&nbsp;give up the ghost. If you don't feel like you've got more hair than you really want, using cool water when washing and rinsing might allow more hair to stick around.</p>
                    <h3>Creaky Knees</h3>
                    <p>I address this elsewhere in more <a href="../Triathlon/TriKnees.html" target="_self">detail</a>. But the short version is, strengthen the muscles that control the positioning of the knee. Those are in the upper leg (especially your abductors) and trunk. That will stabilize your knees and allow you to avoid the torque and strain on your ligaments that eventually becomes painful.</p>
                    <h3>Tender Feet</h3>
                    <p>I've had this strange condition in my feet: increasing numbness on the top of my foot coupled with hyper-sensitivity along the sole of the foot. In a triathlon you have to run from the water to the transition area to pick up your bike. And all I can remember was the agony of it. Every little pebble felt like a nail going in. Strangely, this is typically accompanied by some loss of sensation in other ways. The technical term is neuropathy. Then I read that an artificial derivative of thiamin called Benfotiamine might help. So about five months ago I started taking this small pill every morning. For the first few months I saw no change in the condition. But lately I keep noticing that my feet don't hurt like they used to walking over the plastic pebble mats at the Y.&nbsp;I've got my hopes up. I do believe you have to be very careful with your dosage. If you take too much you can get skin rashes. Well, actually the skin looks fine. But it feels like you have a terrible rash that needs scratching. So I only take 150 mg a day. Be very careful. It's not a prescription where somebody's guiding you in how much to take. You can easily overdo.</p>
                </Col>
            </Row>
        </Container>
    )
}