import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import TwoCentsIndex from './TwoCentsIndex'

export default function Energy() {

    return (
        <Container>
            <Row>
                <TwoCentsIndex />

                <Col>
                    <h1>Is Energy Real?</h1>
                    <p>NOPE.</p>
                    <p>OK, maybe I'd better define my terms. We're talking about what the Chinese call Chi and the Japanese call Ki. Electrical energy is real. There are all sorts of measurable energies in this world. The unmeasurable is not real. It's a well-meaning hoax.</p>
                    <p>I spent a good portion of my life looking for magic. Unfortunately, I began those years studying with Moshe Feldenkrais. And that was my undoing. From Moshe I understood bodies better than most. I ended up feeling like a one-eyed man in the land of the blind. I'd go to all these trainings trying to soak up as much knowledge as possible. There were dozens of body work systems from all sorts of people trying to make their mark (the other day I ran across an old certificate, evidently I've attained the "First degree in the Reiki Method of Natural Healing," three days of mumbo-jumbo and they hand you a certificate). And the person doing the demonstration would talk about how they were altering the body's energy or some-such, and I'd be just amazed. It seemed to me what they were doing was purely mechanical and obvious, yet they'd keep talking about some unseen force they were manipulating. I'd often look around the room wondering if I was the only one who could see what was happening. Everybody else seemed to just accept the gospel as preached.</p>
                    <p>I studied Tai <b>Chi</b>. I studied Ai<b>KI</b>do. In fact I became a 2nd degree black belt in Aikido and taught it at Northwestern University for seven years. Maybe a third of Aikido has to do with joining with the force of the attacker and helping them along. Another third has to do with pain. There are these crazy ways you can twist a wrist that are incredibly painful. People feel like their arm is being removed and instantly plead to submit. Another third has to do with being much stronger than you have any right to be. And here's where it starts to sound magical, except it's not. Anyone who's studied Tai Chi or Aikido has been taught to move from their center. Imagine you want to move someone with your hand. The amount of force you're going to be able to project is approximately a cross-section of the muscle involved. A cross-section of your fore-arm will show very little muscle. But there's another way to do it. Move from your center. To do this the skimpy muscles in your arm simply hold the bones in position and the big muscles in your trunk push through your bones. You move or rotate through your center. You push through bone rather than contracting muscles in your extremities. Instant surprising stregth. Done really well it almost seems magical.</p>
                    <p>It's fun being one of the accolates. It's fun being a magical person who is in on the secrets of the hidden world. But I knew it was a lie. I could offer mechanical explanations for what I saw. In Aikido we do a demonstration to the uninitiated called "Unbendable Arm." You take someone from the audience and ask them to point their arm off into the distance. You tell them to make the arm "strong." Since none of us know what to do with such a command, we tighten up all our muscles. The Aikidoist then bends their arm. For reasons that will be obvious shortly, it's not hard to do. Then we tell the mark to relax their arm. "Imagine energy is pouring through your arm, off into the universe. A veritable fire hydrant of energy pouring through your arm, making the arm unbendable. Thanks to this energy your arm is now stronger than it's ever been. It cannot be bent." You then attempt to bend the arm. It will be ten times stronger than it was before. If you're enough stronger than the subject, you could bend it. But that wasn't our goal. Our goal was that the demonstration be a success. But it always bothered me that I had an alternative explanation.</p>
                    <p>So what the heck are people supposed to do when you tell them to make their arm strong? Relax? No! That just doesn't compute. So people tighten up their arms. A joint like the elbow has two sets of muscles, one for moving the joint in one direction (the bicep for bending) and one for moving the muscle in the other direction (the tricep for extending). If both muscles are tightening at the same time, they cancel each other out. If they're both exerting 50 lbs of force but in opposite directions, then adding a single lb of force might be enough to bend the arm. The subject isn't aware of it, but his bicep is attempting to bend the arm. The tricep is trying to keep the arm straight. The Aikidoist comes in and joins up with the bicep and the battle is won. It's two against one. The arm is easy to bend. If instead of tightening up they relax and only use the muscle that's appropriate (the tricep), then it's much harder to bend the arm. We no longer have the bicep helping us defeat the tricep. Magic.</p>
                    <p>I used to go around the country giving workshops called "Meditation Through Movement." It's was a mishmash of all the stuff I'd been studying. Great fun. (Almost as good as being a rock star if you want to meet some wonderful women.) However, the very first time I gave the workshop I did the Unbendable Arm. I went home that night feeling rather ill. I had joined the ranks of the con artists. I had meant well, but I knew it was a lie. How was this any different than the preachers I detested on the television doing faith healing? So from then on I'd do the demo, and then I'd let people in on the trick.</p>
                    <p>I once asked my sensei (Aikido Teacher - a wonderful man named Fumio Toyoda who died a while back and is missed) about this. I explained the alternative explanation for energy and told him it troubled me to talk about energy in class. He didn't seem surprised. I'm tempted to say he used the word metaphor, but I don't trust that. He was Japanese and I'm not sure his English was that extensive. But he talked about energy as a teaching tool, a way to give people the concept of how to move in a more relaxed and focused way. I did find it a handy tool when teaching. But with my own students I wanted them to know that it had to do with intension and focus, not with magic.</p>
                </Col>
            </Row>
        </Container>
    )
}