import React from 'react'
import { Container, Row, Col, } from 'react-bootstrap'

import SciFiIndex from './SciFiIndex'

export default function SwitchTime() {

    return (
        <Container>
            <Row>
                <SciFiIndex />
                <Col>
                <br></br>
                    <h1 className='text-center'>The Switch of Time</h1>
                    <p>I suppose everyone has at least one story to tell at the end of a long life.  You're about to hear mine.</p>
                    <p> I work at Deep One.  You've never heard of it, right?  We're a jointly funded venture of M.I.T., Cal-Tech, and Galileo Observatory (probably the only thing they've ever been able to cooperate on).  Deep One is about three light years out from anywhere in the hopes of avoiding any hint of a gravity well.  And, of course, that's impossible.  Old Heisenberg again.  Just having a station out here creates a gravity well.  If we tried to beam the signals somewhere, even that would affect the patterns.</p>
                    <p> So when I say we've got a station out here, I don't mean a world, or not much of one.  There's only thirty of us here and the center line's only about a hundred meters long.  They really tried to keep things small.  The net itself is about a hundred kilometers off and a laser beam connects us.  If you want to listen in on the universe, this is where to do it.  No interference, no distortion, no distractions.  Would you believe I've lived in this little nest the last 70 years?</p>
                    <p> This is really the story of Simon Hawkins, or Wrong Way Hawkins as he was once known.  But I only heard about that after I'd known him for about twenty years.  Known doesn't say nearly enough about our relationship.  This is very difficult to explain to people who are not physicists.  But when you're working on a theory with someone, that relationship is more intense than anything else I've ever found in life.  Simon and I were here from the start, back in 2120.  We were teamed up and funded by M.I.T.  Along with our other duties it was hoped our combined talents could manage a description of the shape of the universe without assuming continuous mass distribution.  Don't worry about what that's supposed to mean.  Sometimes I've wonder if our results really do mean anything in the real world.</p>
                    <p> Simon was an experience.  A tall man with homely features and stooped shoulders, he's the only bald person I've ever known.  Now that I think about it, when I first met him he had a full head of curly hair.  But that disappeared quickly.  He had a high forehead from the start and the hairline receded at a furious rate.  I remember asking him about that.  I was curious why he didn't do something for it.  He just said he'd rather not have to worry about such things.</p>
                    <p> That sounds so indifferent, it misses the essence of him.  In the areas that mattered to him, in our relationship for example, I have never known a more passionate individual.  This all must sound strange to you.  You just don't know physicists.  I don't mean sexual passion.  I'm heterosexual and Simon was indifferent to the subject.  At least, I think he was.  I mean he was passionate about his work.  It consumed him.  I suspect he would have had a much happier life if he'd had a little less passion.  But that made him an excellent partner.  He didn't mind calls in the middle of the night when I had a thought worth pursuing.  He'd come right over and we'd spend the rest of the night working out the details.  He never let go of a problem until he'd come up with some kind of a solution.</p>
                    <p> And such a mind.  He didn't need to write things down.  You can't imagine the enormity of what I'm trying to say.  He once explained an equation to me, a monstrosity of a thing, and I soon lost the thread.  I asked to see it written out.</p>
                    <p> He said he hadn't written it out yet.</p>
                    <p> I just about fainted.  Could he write it out?</p>
                    <p> "Sure," he says, and does just that.  It must have gone on for five pages.  He wrote it down like he was copying it off a screen in his head.  I've been around a lot of brains in my day, but never one like his.</p>
                    <p> I was talking about a relationship.  Can you imagine the two of us sitting together hour after hour?  Thinking.  Then one of us would get a new angle on the problem.  We'd talk excitedly for a few minutes and then go back to thinking.  Just sitting there.  It was the most exciting thing I've ever done in my life.  It was rapture.  Unless you've tried it you should withhold judgment.</p>
                    <p> So we were very close.  Except it was such a one dimensional relationship.  We could never talk about anything but the work.  He was a hard man to know and a hard man to like.  He never married.  I was probably the only friend he ever had.  It's just... Do you know how foolish you feel when you realize you've been talking to yourself?  Or the embarrassment when it dawns on you that you've been rambling?  Well, sometimes I'd be talking to Simon about something other than the work, and suddenly he'd say something, point out a relationship between two variables, and I'd realize he hadn't heard a word I'd said.  For Simon there was nothing but the work.  He seemed unable to turn his attention elsewhere.  I sometimes wish he'd been able to spread the passion out a little bit.</p>
                    <p> It was within a week of Deep One's inauguration that the computer showed us the first message.  In one sense that was what Deep One was really all about, finding out if there was anyone else out there.  It had other assignments, but that was the big one.  And within a week of our arrival, there it was.  All tied up in a bow.  A set of impulses with logic and structure to them.  All we had to do was remove the packaging.  I can't tell you what those days were like.  We were all in a fever.</p>
                    <p> It was up to the computer to interpret the signals.  This was a tenth generation Cray we were using.  The best mind our race has ever created.  We told it to sound general alarm when it had something, and we all pretended to go back to work.  Except we couldn't.  Once a day we interrupted the computer's processing long enough to make sure it wasn't caught in some impossible loop.  We were being a nuisance; a computer of its caliber checks for its own loops.  But we interrupted anyway.  Then we would apologize for the interruption and let it go back to work.  After three weeks it reported it could not crack the code.</p>
                    <p> I don't think we had considered that possibility.  Because, you see, the signals shouldn't be in code.  If someone is trying to communicate with us, they should be doing everything within their power to provide a means of translation.  We know how we would do it.  There have been thousands of papers written in the last two centuries on this subject of establishing communication with another species.  Our Cray had in its database the logic structures for every theory the human race had ever come up with, and nothing provided even a trace as to the message's meaning.</p>
                    <p> Finally we swallowed our pride and made the message public.  We turned the combined intelligence of the human race loose on the puzzle, and got nowhere.  That was back in 2120.  If you're as old as I am, you'll remember.  For nine or ten years the Alien Rosetta was a big deal throughout the media.  Everyone knew that the person who cracked it would go down in history.  Well, the message--actually messages, there ended up being dozens of them over the years--were turned inside out and permuted in every manner known to man, and after about ten years the world of science gave a collective sigh, and the Alien Rosetta dropped from sight.  I'm told no one ever mentioned it at parties; panels on the subject ceased.  The pain of failure was quietly left behind us and our species went on to more satisfying endeavors.</p>
                    <p> Except Simon could never quite let go of it.  Because the messages kept arriving, here, right on our doorstep, regular as clockwork, once every 256 days.  It became a sort of hobby for Simon.  He'd stare at the pattern for hours on end.  Thinking, I suppose.</p>
                    <p> At about the time the rest of the world gave up on the Rosetta, Simon and I officially ended our relationship.  It was time, I think.  We'd published a paper with our conclusions and received half a dozen nice telexes from the few people in the universe who could appreciate what we'd done.  I'd met Ruth by then, and she and I, in addition to raising a family, had decided it would be fun to work together.</p>
                    <p> That freed Simon to spend all his time on the messages.  He wrote a program to help him.  The program was a life's work in itself.  He called the program Cracker.  If Simon had been a literate person he might have called it Sherlock, or something clever.  But no, not Simon.  Cracker.  I suspect he spent maybe half his time altering the program and half his time talking to it about avenues of approach.</p>
                    <p> I would stop by on rare occasion, more out of a sense of obligation than because I enjoyed the visits.  He would ramble on, lost in another world, and I would listen, understanding little of what he said, saddened by memories of the years when understanding between us had been instantaneous.  In some sense I suppose I was jealous.  The old story of leaving your lover only to resent the new relationship which comes along to replace you.</p>
                    <p> For Simon had found a new love, and his thoughts followed paths I would never travel.  He may not yet have fathomed the meaning of the messages, but he knew they were generated by an intelligence of some sort, and that was enough.  He knew there was something out there somewhere, and that search for understanding, for a connection with the source, became his life.  Say what you will about the lack of feedback and response, Simon's search was still a relationship.  When I think of the level of commitment and passion Simon brought to the quest, it was almost holy.  Maybe that seems far fetched as you read this.  But if you could have looked into his eyes...</p>
                    <p> We grew apart, Simon and I.  I had a wife and two children.  My interests grew far beyond the equations which had so shrouded my years with Simon.  Ruth was good for me in that way.  She became my mentor in a dozen areas.  And she made me a father.  Now that the children have left the station, I miss them.  My kids taught me to play.  I wanted their smiles so desperately that I started smiling myself.  I lightened up considerably in those years.</p>
                    <p> Simon, on the other hand, seemed to grow into his computer.  His body became thin and flaccid.  It was not that he was unkempt, just that his body did not matter and he became skin and bones plus an incongruous little pot belly.  It was a sad thing to see.  I would guess he had the physical conditioning of an eggplant.  I felt sorry for him, and I suspect he felt sorry for me.  Sorry that I could not join with him in the ecstasy of his search.</p>
                    <p> When tied to later events, the incident I am about to relate may stretch the limits of credulity, the coincidence too unlikely to be believed.  But the symposium transcript is a matter of public record.  Switch into any scientific library, and take a look at the log for 2116, under Galileo Conference.  I do not believe irony is ironic.  I believe there are unseen connections and that coincidence is but the connections we cannot see.</p>
                    <p> The incident in question was related to me by a fellow named Albee Fisher, who had seen Simon in action at Galileo.  Albee had flown into Deep One for a year's rotation on the Theoretical Physics Chair that M.I.T. maintained, and I had invited him over to my place for dinner.  After dinner we were talking in my work room, when he said, "By the way, I want you to know I read that paper you and old Wrong Way wrote.  Very nice job."  Well, I'd written a number of papers in my time so I wasn't sure which of my collaborators he meant.  I asked him.</p>
                    <p> "Hawkins," he says, "Wrong Way Hawkins.  Haven't you ever heard that one?  Obviously not," he answered his own question.  Say what you will, most physicists are not stupid.</p>
                    <p> So I got him to tell me the story.</p>
                    <p> It seems when Simon was a young grad student just up from earth, he had presented a paper at the annual symposium entitled, 'The Reversal of Time.'  He had proved mathematically that time would reverse itself in a few million millennia and run backwards.  He even thought he had spanned the singularity.  Talk about going for the big one.</p>
                    <p> If you think about it a second, time reversal is not all that hard to accept.  What is time?  The vibrations of the quartz in your watch?  The movement of currents through your nervous system?  Time is movement, the trembling of life, the burning of suns.  And the universe is winding down.  Moving from the ultimate order of the protomatter awaiting the big bang to the chaos of final entropy when the universe is still and cold.  Time will then stop, for there will be no movement.  But time may stage a comeback.  When the moment of ultimate dispersion comes, when entropy has reached its zenith, gravity will still be there, quietly working, reversing the process, creating movement once again.  And if you handle all of this with equations you can prove with a sort of sleight-of-hand that time will go into reverse, moving backwards down the other side of the hill.  At least, that's what Simon thought he had proved.</p>
                    <p> In order to understand what Simon had attempted in his final equation you have to know what a singularity is.  A singularity is the great ending of endings, the ultimate unknown.  A moment through which nothing can pass, no laws of nature, no cause and effect, nothing.  A moment when everything is recreated.  The granddaddy of all singularities is the big bang, that moment when all of creation is compressed into a thimble and the play begins anew.</p>
                    <p> Describing that singularity is still the last juicy plum left to physics.   We all know that if anyone can do so, his or her name will go up there in the pantheon with all the other legends.</p>
                    <p> So you've got to imagine this kid who thought he'd built a bridge across the singularity, from one creation to the next.  He stands before the symposium, ready to present his final screen, ready to knock the scientific establishment on its collective rear end.  And here, right out of the transcript, is his big finish, "The singularity, almost by definition, is the end of all law, all reason, all continuity of events.  But I beg to disagree.  I believe I can show you that something would span the singularity.  Some would call that God.  For I maintain there is continuity.  Here is the equation.  I offer you the face of God."</p>
                    <p> I wince with you.  Some things that our friends do need to be overlooked.  He was young.  We were all young once.  I fear some will see that last line as the height of arrogance.  But I'm sure Simon didn't mean it arrogantly.  As corny as it sounds, his devotion to physics was a quest for truth, and love, and beauty.  For him, that final equation really was God.</p>
                    <p> By my crystal, the truly arrogant character in this little vignette is Raphael Nogoda, now deceased, then head of the Physics Department at Galileo.  The way Albee tells it Nogoda did everything he could to remove Simon's navigation pulsars before spinning him adrift.  (I once met Nogoda, and somehow the story doesn't surprise me.  He was a nasty little man.)</p>
                    <p> It would seem Nogoda was on his feet the second Simon finished presenting.  Instead of asking a question, the old prima donna ignored Simon and began lecturing the conference attendees as if they were a class of undergraduate students.  "I find this one of the most infuriating tendencies in the new generation.  Physics is not religion.  Despite popular folk lore, science does not progress by leaps of intuition, but by sweat, and discipline, and attention to detail."</p>
                    <p> While the transcript does not include stage directions, I imagine Nogoda with a sneer on his face as he delivers his sermon.  His behavior was astonishingly rude, and I suspect more than a few were annoyed.  But in the end he left them laughing, and that's what people tend to remember.</p>
                    <p> Simon finally interrupted and demanded to know what the problem was.</p>
                    <p> "Our problem is inadequate screening of presentations which then allows young fools who have not yet mastered basic algebra, who have no sense at all of the methods of scientific inquiry, to waste the time of this conference."</p>
                    <p> For the first few rounds Nogoda kept the charges vague and insulting, drawing it out, suckering Simon deeper and deeper into the ambush.  If you want a lesson in how to humiliate someone, take a look at the transcript.  Simon, of course, stood his ground, passionately if I know Simon.  Nogoda raised the stakes, "Any child could tear apart your premises."  When Simon had finally staked his career, his professional reputation, and his mother's honor on the correctness of his conclusions, the old man cut the umbilical line.</p>
                    <p> You see, the trouble is, Nogoda was right.  Simon had foolishly not shown his work to anyone and had made a simple and very elementary mistake in one of the supporting equations.  Knowing Simon, I even think it possible he hadn't put any of this into core memory until the day he screened it before the symposium.</p>
                    <p> In a rather impressive display of brain matter, Nogoda had spotted the error as it flashed by.  Before Simon knew what had hit him, the old man was up on the platform, pointer in hand, explaining simple algebra to the delighted assemblage, milking the moment for laughs.  The symposium had its one really raucous climax of comic relief for that year and Simon transferred down to Renssellaer to finish his graduate thesis.</p>
                    <p> But the word got around fast, and from then on he was known as Wrong Way Hawkins.  Albee was amazed I had never heard the story.  He added, "I guess Hawkins wanted someplace to disappear to after that."</p>
                    <p> For a second I didn't realize what Albee meant.  He was referring, of course, to Deep One.  I've never thought of this as a place to run away to, but if you think about it; until they open a hermitage in the Coalsack Nebula, Deep One will serve nicely.  As I look back, Simon and I are the only two of the original team who stuck around through the years.</p>
                    <p> About a week later I made the mistake of asking Simon if I could see his paper on time reversal.  I should have just looked it up, but I guess I was secretly hoping he'd be willing to talk about what had happened.  He turned his back and walked away as if he hadn't heard me.  I never brought it up again.</p>
                    <p> Simon died about five years ago.  Died of a heart attack, if you want the full story, his body so weak that the Medical Unit couldn't keep him alive.  His system was so lacking in internal fortitude or gristle or whatever it is that holds us together, that wherever the medical system attempted repairs it just did even more damage to tissues that couldn't handle the stress.  Maybe it happens, but I'd never heard of a body so deteriorated that the machines couldn't keep it alive.  That was Simon.</p>
                    <p> I went down to the lab when I heard.  He looked so sad lying there.  There was something in his face, a grief so profound I could hardly bear to look at him.  You would think in death the muscles would let go, but they hadn't.  I don't believe I've ever seen the like.  Maybe I have crossed grief before, but I have never looked it full in the face and studied it.</p>
                    <p> Inexplicably, as I was standing there looking into that sadness, I suddenly felt the old joyful communion upon me, the pleasure of following his logic, the delicious surprise each time we hit a snag and Simon would shift our perspective on the problem, the rapture of two minds leapfrogging each other as one idea poured out upon another.  Then I was left with a black, yawning emptiness within my bowels.  Where could such a mind have gone?  And where, all too soon, would I follow.</p>
                    <p> A chime dragged me back from the abyss.  When I looked up the station head was peering down from the wall screen.  "Someone's going to have to tidy up Simon's affairs and move his belongings out.  You willing?"</p>
                    <p> "Sure," I said.  I opaqued the tomb and wandered over to his room.  His screen had been turned off so I turned it on, figuring somewhere in his files I might find a will.  I found myself staring at the following lines:</p>
                    <p><em>We begin in the beginning,</em></p>
                    <p><em> When we are all God and God is within us.</em></p>
                    <p><em> We begin as ultimate order</em></p>
                    <p><em> And rush to chaos.</em></p>
                    <p><em> We begin as flame and become death.</em></p>
                    <p><em> And there at the limits of entropy</em></p>
                    <p><em> We offer you the switch of time.</em></p>
                    <p><em> We now begin the universal love,</em></p>
                    <p><em> To share our heart with other times.</em></p>
                    <p><em> In the beginning of the beginning there was union.</em></p>
                    <p><em> And this is how we begin this work.</em></p>
                    <p><em> From here all understanding follows.</em></p>
                    <p><em> 1 + 1 = 2</em></p>
                    <p>From there it was all by the book: the binary numbers, the building of a periodic table, a course in mathematics, basic biology.  I pulled down page after page of the stuff.  Simon and Cracker had cracked the code.  I could feel my pulse beating in my brain.  I suppose I'm lucky I didn't have a heart attack as well.  So why hadn't Simon died with triumph written on his face?  I checked the date on the file.  Three days before.  Simon had cracked the code at least three days before.  Why hadn't he told anyone?</p>
                    <p> For a second I remember thinking I could transfer the files over to my storage, wipe Simon's files clean, and a few years from now announce my achievement to the universe.  Immortality is a tempting carrot.  But I obviously didn't.  I just kept reading.</p>
                    <p> About twenty pages down I came across the line, "Till we talk again, till then."  The next page was listed as file two.  It was a dictionary.  This symbol is defined by these other symbols.  The code book if you will.</p>
                    <p> The third file was a continuation of the dictionary.</p>
                    <p> The fourth file was entitled "The Switch of Time."</p>
                    <p> If the first transmission had been general, this fourth one was specific.  The information, the equations, were definitely going somewhere.  Finally I found a line which read, "At this moment begins the reversal of time."  I remember a cold wave rushing through me.  The most delicious moment of realization I think I've ever experienced hit me.  Suddenly the equations I had been reading all fit together.  I got the point, as they say.  The file showed the relation between entropy and the direction of time.  There was the proof Simon had failed to ace all those years ago at the symposium at Galileo.  The next equation described a universe in which all time moves backwards as the universe begins its long collapse back upon itself.  If this did not provide triumph for Simon, it at least provided vindication.  Surely finding that would have been a moment of delight for him.  Such screens could not have been what etched those lines on his face.  I remember feeling bewildered by the mystery of it all.</p>
                    <p> So could time someday actually change directions?  Does it really mean that we would all live again, living our lives in reverse like the tabloids announced?  Part of me still can't believe that time can have two directions.  If you drop an egg, it breaks.  In reverse time, does it reassemble?  The hilarious thing is, there is nothing in all of physics to preclude the possibility.  Common sense does, but in physics common sense often just gets in the way.  Despite herculean efforts by all the Nogodas of this universe, no one has been able to find a flaw in the equations.</p>
                    <p> The final equation in that sequence was their gift to us, the formula for inverting entropy, for reigniting the universe without reversing time.  You may think that sounds crazy, but it evidently worked for them.  Because they say they did it.  They say they've been there, and slid down the other side, and lived to tell the tale.  As the file ended, there was once again the line, "Till we talk again, till then."</p>
                    <p> I continued on, my finger glued to the page advance key.  It was the most fascinating day I've ever spent.  Much of it I merely skimmed.  Many of the equations were familiar.  Much of what they offered we had already discovered for ourselves.  Other processes I could not fathom.  Finally, as my eyelids began to droop and I fought back sleep, I asked Cracker to flip to the end.</p>
                    <p> The last few lines read:</p>
                    <p><em>This is the little we have learned of the Universal Laws.</em></p>
                    <p><em> Next we speak the angles of the heart,</em></p>
                    <p><em> The equations of our lives.</em></p>
                    <p><em> We will not tell you truth</em></p>
                    <p><em> But humbly tell perceptions.</em></p>
                    <p><em> We are the instruments of  learning,</em></p>
                    <p><em> The tools of understanding.</em></p>
                    <p><em> We are the pieces in the play,</em></p>
                    <p><em> The pawns ennobled by our struggles.</em></p>
                    <p><em> We live forgetful of our connections.</em></p>
                    <p><em> We struggle with pain and isolation.</em></p>
                    <p><em> Only in the sleep of sleeps do we return to love and healing.</em></p>
                    <p><em> Only then do we see what we have learned.</em></p>
                    <p><em> Till we talk again, till then.</em></p>
                    <p>I have no idea how long I stared at that screen, reading it over and over again, enjoying the accidental rhyme of the last line.  I wondered if this had been a poem of sorts, if the original lines had rhymed and danced.  I wondered how much of the humanity I read was in the translation.  These people, whoever they were, didn't have physical hearts.  The program had chosen that word as a best case interpretation.  This was a totally alien civilization.  Set a computer to find meaning and it creates meaning--and we do the rest.  They couldn't possibly be as human as they sounded.</p>
                    <p> Except my human heart did not believe that.  There was kindness and wisdom on every screen.</p>
                    <p> What follows is the part that does flip flops with my own brain.  I can only imagine how bizarre it will sound to someone who hasn't dealt with the ephemeral truths of equations all their lives.</p>
                    <p> I had been talking to old Cracker as I paged down through those amazing records.  After staring at that last screen for I don't know how long, I finally asked, "When is the next transmission expected?"</p>
                    <p> "There will be no further transmissions," the disembodied voice explained.</p>
                    <p> "How can you possibly know that?" I asked, a little outraged at the program's hubris.</p>
                    <p> "The last transmission was their first transmission."</p>
                    <p> Was I being obtuse?  Do you see it already?  "Look," I said, "We can't possibly know when their first transmission was because we weren't able to receive the signal until we built Deep One."</p>
                    <p> "No.  We will not be able to receive their subsequent transmissions because Deep One was not available to do so.  This last record was the first one we received.  File one is the last one we received.  Each transmission we received was inverted.  Their time moves contrary to our own."</p>
                    <p> I stared at the screen for some time, all sorts of muddled, disconnected thoughts buzzing through my head.  For someone who has spent his life thinking logically, it is difficult when you can't bring that process under control.  I could not follow the thread.  "How can you be certain this will be the last?  When is the next one due?"</p>
                    <p> "Certainty is a null concept.  Yesterday."</p>
                    <p> It took me a moment to sort out the answers.  Asking a computer too many questions all at once can be confusing for everyone concerned.  It took another moment for it to all sink in.</p>
                    <p> So they were gone, into our past I suppose.</p>
                    <p> But where were they?  What dimension?  Even for a physicist, the question is confusing, especially when one no longer deals with abstracts but with entities which live and think and send us messages which speak of God and love.  They, what a shame they never gave us a name.  Though I suppose a name wouldn't mean anything.  Most names don't.  I felt the loss then.  For the first time I truly experienced their passing, the missed rendezvous in the night.  Think of all the years our species has spent looking for another kind with which to share our dreams.  And we'd found them, and lost them, all in a moment.  After all those loving years, imagine how Simon must have felt.</p>
                    <p>&nbsp;</p>
                </Col>
            </Row>
        </Container>
    )
}