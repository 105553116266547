import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import TriathlonIndex from "./TriathlonIndex"

export default function TriRules() {

    return (
        <Container>
            <Row>
                <TriathlonIndex />
                <Col>
                    <h1> Rules </h1>
                    <p>The rules are entirely about keeping everyone safe and making it a fair race.</p>
                    <p>They expect sportsmanlike conduct.
    </p>
                    <p>You have to wear a helmet on the bike leg.
      </p>
                    <p>The helmet must be buckled.
      </p>
                    <p>You can't have a glass container in the transition area. (Racers will at times be barefoot and the glass might break.)
      </p>
                    <p>You can't wear a music device and ear buds. (Bummer, but you're safer being able to hear cars coming. Take your chances when other people aren't liable for your safety.)</p>
                    <p>Arrange your transition area so you're not getting in other people's way. Your equipment should be on the same side of the bike rack as your down (resting on the ground) wheel. When you get back for T2, rack your bike. Don't just toss it on the ground.</p>
                    <p>You can't draft in the bike leg for most races in the United States. (International competitions tend to allow it.) That means you can't get on someone's tail and let them do some of the work. You have to keep several bike lengths behind the person in front of you. If you want to pass, do it like you mean it (you've got 15 seconds to finish the pass). Don't creep up on their tail and then take your time about passing. (Most of the time penalties in a triathlon happen this way.) On the bike you should stay to the right. If you wander into the left side of the lane and stay there for more than that old 15 seconds, you'll be penalized. The left side of the lane is reserved for passing. If you're going to pass, move over into the left lane and then peddle to the metal. If you're the one being passed, you're at first allowed to challenge them by speeding up yourself. But if their front wheel inches ahead of yours, then you're passed. You're obligated to ease up and let them take the lead. Let them get a few lengths ahead before you try and match their speed. If you're annoyed about getting passed and want to pass them, that's fine. Once you've fallen back out  of the drafting zone, you can switch to the outside and now you're the one trying to pass. But again, do it quickly. You should be strong enough to pass in 15 seconds or else you could get penalized. Part of me thinks it would be fun if people could ride alongside each other and chat. That's one of the fun things about the run. You have some conversations, share encouragement, etc. But the powers that be are worried about two side-by-side riders blocking the road and making it dangerous for a third rider to get by them without getting too near oncoming traffic. So you can't ride alongside each other. That's just the way it is. On a short course, drafting can result in a  2 minute penalty. Do it again and the penalty doubles. Do it again and you're disqualified.</p>
                    <p>Don't litter the course with your equipment or junk.</p>
                    <p>Wet suits may be worn up to and including a water temperature of 78 degrees Fahrenheit. You may still wear a wet suit up to 83 degrees, but in theory your time will not count when they're handing out the medals. At 84 degrees and above you risk overheating in a wet suit and so they're not allowed. Finally, the material of the wet suit must be no more then 5 millimeters in thickness. End of rules. I will add that I've never actually seen race officials keeping track of who was wearing a wet suit when the water temp got between 78 and 84 degrees. I just don't think they worry about it all that much. On the other hand, one of the things I really like about triathlon is the sense of sportsmanship. If you place in the standings and you wore a wet suit above 78 degrees you need to check with the officials and leave it in their hands. A medal on a ribbon isn't worth lying about. I've got my hardware hooked over a lamp above my desk. In theory I'm proud of them, but in reality I have no idea what to do with them.</p>
                    <p>So now a story from one of my triathlons, followed by a quiz. The race had just begun and we were swimming for the first buoy. Everything was going smoothly. And then  maybe only five minutes into the swim I got hit with a wave right at the moment I was gasping in a deep breath. I don't know what happened, but boy was it unpleasant. I do know I swallowed a whole lot of water. I immediately was in a lot of pain, a combination of wishing I could throw up and wanting desperately to belch. When on my stomach the pain was disorienting. But on my back it was bearable. I spent the next half hour thinking that if I just didn't give up I could continue on with the race. I'd spend a minute on my back and then roll onto my stomach and try swimming. That would last about 5 seconds because the pain would double and I'd roll onto my back once again. I'd wait another minute and then try again. Surely something would change eventually. But it never did. Since I was obviously in trouble, for most of this time a kayak hovered nearby. I remember wishing I'd worn a wet suit that day. It would have made the floating a little easier. Finally I was getting so tired it just seemed pointless to keep hoping for a change. I agreed with my guardian angel in the kayak that it was time to call it quits and, accepting disqualification, I grabbed the rope hanging off his bow. So here's a question for you. Was I disqualified? Obviously I was, but actually I wasn't. I hadn't read up on the rules. You're allowed to hang onto a buoy or some other object in the lake if you need to. If it's a shallow lake and your feet can touch the ground, you're even allowed to stand up in the water. After all, that's just what you'll do when you're leaving the water. Back to our story.</p>
                    <p>So the mother boat pulled up and I climbed onboard. I sat down and immediately began a 3 minute belch. Maybe it wasn't a single belch. Maybe it was multiple belches. But it went on quite a while, and at the end I felt fine. Absolutely fine. It was such a shame I couldn't hop back in the water. So, could I have? Actually, yes. Should have learned the rules. The boat was dead in the water as I sat there belching. Since the boat was not moving in the line of direction of the race, it had not propelled me toward the finish line. The second it moves in race direction, I'm disqualified, but until then, no. There's one last consideration. Once I got on the boat, the race official in charge has to be OK with me continuing. If he thinks it's not safe for me to be allowed back in the water, then it's all over. No arguing. Or if the boat needs to rush off to help someone and considering my wishes would get in the way of that, too bad for me. But if we are both OK with my continuing, I can. So now you know. For what it's worth, even if you're disqualified for not finishing a segment, you can still keep going. I didn't want to slink home a total failure, so I went ahead and finished the bike and run. No one's likely to mind. Just stop by the scoring hut and explain what you're doing. That way the officials are not left scratching their heads over your strange timing-chip results.</p>
                    <p>There is a section in the rules regarding bike dimensions. The upshot is that just about any bike will qualify except a recumbent bike. If that's what you ride, I think you're out of luck. If you have an unusual bike, you might want to download the rules PDF and study up.</p>
                    <p>There are other rules: You're expected to be a member of USAT, even if just for the day (that pays for your race insurance). You're expected to be physically prepared for the race. You can't use a propulsion device in the water (mini submarine, fins, etc.). The rest of it is mostly common sense. But it never hurts to have studied it for yourself. The USAT has a downloadable PDF version of the rules <a href="http://assets.teamusa.org/assets/documents/attached_file/filename/28818/usatcr.pdf" target='_blank' rel="noopener noreferrer">here</a>.</p>
                    <p>That race-day membership in USAT will cost you $10. Consider just signing up for the year. It's a good organization. You won't have to pay an additional $10 each time you race that year. And there's a quarterly magazine that comes with it. If you think you're going to love triathlon, become a member.
    </p>
                </Col>
            </Row>

        </Container>
    )
}