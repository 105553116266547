import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import TriathlonIndex from "./TriathlonIndex"

export default function TriOpenWater() {

    return (
        <Container>
            <Row>
                <TriathlonIndex />
                <Col>
                    <h1> Open Water Swims</h1>
                    <p>When you get to the open water, everything's different. My advice to someone getting into triathlon is to first sign up for a sprint race where the swim portion is in a pool. There'll be enough challenges in your first triathlon without adding the extra anxiety of an open water swim. Leave that for later. The trouble with a lake or the ocean is that there are no lanes, no lines, no guidance on where to go. Most of us pull to one side or the other and don't even realize it because we correct in a pool. We adjust based on the lines. I've managed to swim a distance 50% greater than the course, simply by zigzagging across the lake. Back and forth, back and forth. I've also twice failed in open-water swims by swallowing a mouthful of lake water and having stomach distress. So everything here is offered with utmost humility. However, I'm getting much better at it and can tell you what I've been learning.</p>
                    <h3>Laziness</h3>
                    <p>My basic problem was laziness. I'd developed this nice stroke and had learned to love swimming. Sighting involves breaking out of the perfect stroke and working hard for a second. I hated it and avoided putting in the practice I needed to do. Somewhere inside me I just expected I'd get lucky.  I'm an optimist by nature and that can get you into trouble. I told myself I'd just follow the crowd. So it's race day and we're swimming across a lake. And for the first few minutes I was deliriously happy. I was swimming in the midst of a pack of swimmers and I was checking as I breathed left and then as I breathed right and there were people all around me. So I was exactly where I wanted to be. Didn't have to sight. The wisdom of the pack would get me where I wanted to go. And while the water was murky I could even pick up on activity ahead of me and suspected I was getting a little help from drafting. I could just focus on my stroke and coast to success. And, wouldn't you know, someone kicked me in the head and knocked my goggles off.</p>
                    <p>The good news is I was ready for the possibility. I'm constantly looking for good advice, and I once ran across the suggestion of putting your goggles on first and your swim cap on top. That way if someone knocks your goggles off, the goggles won't get swept away from you. So thanks to my swim cap the goggles couldn't go anywhere. I treaded water and got my goggles back in place. All was well. Only trouble is, I'd lost my school of swimmers, and now I was on my own. A really strong swimmer might have been able to catch up and get in with the crowd again. Except I'd been too lazy doing laps to work on strength and sprinting. I had no kick. So I was on my own. Ah well. I buried my head and started swimming. A minute later I paused and checked how I was doing. And I was totally off course. I reoriented. Another minute swimming and I checked again. Way off course again. Right about now I'm thinking, "I wish I was better at this sighting business." I added at least 50% on to the length of that swim by zigzagging back and forth across the lake that day. I'm sure I came in last in my wave. You can be less prepared than you'd like on the bike and still get through it. You can walk on the run. You can't fake the water. You have got to go into the water strong and fully capable. You've got to have a sighting stroke that you can insert into your normal stroke every 10 seconds if need be. If you don't make the effort in practice, you'll pay for it when you race.</p>
                    <p>The two things I'm working on to solve this zigzagging business are swimming straight and sighting. Swimming straight involves making sure I keep my catch straightforward, no kinks. Each hand goes straight out directly in front of the shoulder. I'm working to eliminate any clever z-curves from my pullback. I had seen guys in the Olympics doing that and it seemed like a good idea. And maybe in the pool you can make it work. But in open water it adds an element of chaos. (I'll mention that one of the best open-water swimmers in the world recommends the z stroke. I'm avoiding it.) Instead I want my hand to pull straight back through the water.</p>
                    <p>And, of course, you have to develop some means of looking to see where you're going. So you'll have to lift your head. Except I've spent months and months training myself not to do that. Because my feet will go down and I'll lose momentum each time I look up. The ways you compensate are, 1. Don't lift the head too high. 2. Do it quick. Get the goggles out of the water, take a quick picture of what's ahead and then face to the side to breath. 3. Compensate with your kick. You know how I said full bore kicking wasn't needed in a triathlon? Well, I lied. The one thing that kicking really can help with is keeping the feet from dragging. If you accelerate your kicking as you lift your head to sight, it will keep your feet from dropping and help you keep your momentum. Then revert to a two beat kick, or whatever you've chosen as your kicking style. With that said, I'll admit that I found this very difficult to master and for a while used a different approach. I'd breathe to the right side followed by two strokes with my head up. I'd sneak in a last gulp of air and then three strokes head down, breath left, three strokes head down, breathe right, sight again for two strokes. That worked for me. It's advantage was you could really get your head up high and get a good look. It's disadvantage was that it really took a lot of energy. I found myself going back to the straight head's-up-and-then-breathe-to-the-side approach. The key for me was learning to balance on that lead glide-hand for a millisecond as my head came up. Eventually I somehow managed to learn to lift the head without using the rest of my body to do it. Sighting no longer seems that difficult and I no longer resist doing it.&nbsp;But it took a while to get here.</p>
                    <h3>Breathing</h3>
                    <p>I've mentioned this elsewhere, but I'll mention it again. When I ingest water, I'm screwed: stomach cramps, vomiting. I've had it ruin two different races I've been in. It shouldn't be that big a deal, and perhaps for you it won't be, but for me it's critical to avoid such moments. So I exaggerate my head turn and look at the sky to breathe. I want a huge margin of error on my breathing.</p>
                    <h3>Goggles</h3>
                    <p>Finding the right pair of goggles can be a huge problem. I'm still not happy with my results. If your goggles leak a little bit in the pool, you simply stop a second and reset them. That gets ten times harder in open water. A small leak and the water gets in and makes it impossible to sight&nbsp;well. So you try and get lucky and just swim. Except now you're swimming blind and adding significantly to your distance. In open water you need goggles that will keep the water out forever, not just 10 minutes at a time. Go through whatever hell you must to find a pair of goggles that work for you. Otherwise, everything else falls apart. Part of making goggles work is often getting them adjusted properly. Sometimes you simply have to tighten the straps, sometime the straps being too tight is what's distorting the goggles ability to make a seal. Sometimes the goggles just aren't right for your face and you'll need to try a different brand. And finally, make sure you're confident resetting your goggles on your face while treading water. Because no matter how good they fit, sooner or later you'll have to do so.</p>
                    <h3>Kicking</h3>
                    <p>I've started practicing my kicking. I know I said that 6 beat kicking wasn't needed in a triathlon, and I still believe that. But it's nice to have power when you need it. A lot of people kick in the beginning of the swim to find their position. I've also heard that kicking strong as you finish will help get the legs functional again after a long swim.</p>
                    <h3>Planning Ahead</h3>
                    <p>Sighting is pointless if you haven't done your homework. You have to know what you're looking for. Before the race ever starts get clear on where you need to go. Ideally you want to be able to sight from one buoy to the next, for each leg of the swim, and study what you're seeing. The buoy's are not that easy to spot. Sure they're usually orange or something, but they're sitting on the water. You really need to get your head awfully high out of the water to see them. Instead study the skyline behind the buoy. What landmarks are big and obvious? Burn an image into your head so you know where you want to go based on the larger picture. Learn to take that snapshot of the view as you lift your head and then be able to analyze things when your face is back in the water to figure out what adjustments you need to make in your direction. It's a real skill and I'm still struggling to master it.</p>
                    <p>You'll also hear advice about drafting and using the swimmers around you for guidance. I've heard the suggestion to follow the bubbles created by the swimmer ahead of you. Trouble is, the water often isn't clear enough to see much when your head is under water. So pay attention as you breathe. If you breathe to the right and see a bunch of swimmers and you breath to the left and see a bunch of swimmers, you're in the middle of a pack. And there are people in the pack sighting. There's a group wisdom to this sort of thing, and the pack is probably headed in the right direction. Just stick with everyone else. (Thought it wouldn't hurt to verify once in a while.) If I sight and fail to get an impression of my target, I ask myself if I could see any colored swim caps around me. If I seem to be going down the middle of this collection of impressions, I figure I'm OK. But if you go into a race and then lose the guidance of the pack, you bloody well better be able to figure out where to go on your own. Don't assume you'll get lucky.</p>
                    <p>You can always just breast stroke for a few seconds to get your head out of the water and orient yourself. But if that's all you know you'll be loath to do it continuously, because each time you'll lose your momentum. And so you won't do it often enough and you'll (all together now) zigzag. Instead you need to learn how to sight and develop a rhythm that allows you to do it every ten seconds or so. Actually, this depends on how straight you swim. If every time you sight you're on track, then by all means stretch out the interval. Sighting is hard work and needs to be included constantly in practice, otherwise it will take too much out of you when you really need it.</p>
                    <h3>Drafting</h3>
                    <p>If you can stay with other swimmers, you can draft. And unlike the penalties incurred for drafting on the bike, here it's allowed. If you can keep on someone's tail, you'll expend a lot less energy than if you're on your own. They say it's 75% the effort of swimming on your own. While the best position to be in is directly behind someone, you'll get some benefit off to the side with your arms even with their legs. You've seen a V formation of geese flying. Well, every goose in that formation (with one exception) is drafting. Smart geese. And, or course, the one goose who's doing more work than any other is the poor soul at the tip of the V. If you're out swimming in a pack with friends you can draft off each other. And just to be fair, every so often the lead swimmer should drop off the front and join in at the bottom of the line. Let someone else shoulder the extra work for a while. I don't know if the geese follow such courtesies. If, as you're racing, you occasionally feel someone's fingers brushing your feet, be OK with it. Someone's drafting off you and the contact is inadvertent. It really won't hurt you in any way, and someone's getting a little help thanks to you. Find  someone ahead of you that you can draft off of and enjoy being part of a team effort.</p>
                    <h3>Getting Knocked Around</h3>
                    <p>It's rude getting kicked in the face, but you've just got to not take it personally. Sometimes it feels like someone's swimming right over you. Sometimes someone actually is swimming right over you. Try and be OK with the bumping. Look on the bright side. If you're in a tight group of people, you're probably getting the benefits of drafting.</p>
                    <h3>Acclimating </h3>
                    <p>Swimming in really cold water is just painful. For me anything below 60 degrees Fahrenheit and I'm in agony. One piece of advice I've run across is: if you're about to race in really cold water, don't stand there on the shore dreading it and trying to stay warm. Get into the water ahead of time and acclimate. This takes at least ten minutes. I can remember water so cold just putting my hand underwater was painful. When I tried swimming I could only last a few strokes before wussing out. But if you stick at it, putting your hands in the water, splashing your face, pulling your hands out of the water, bobbing around, putting your hands back in the water, you somehow get to the point where it's bearable. Just be thankful that triathlons allow wet-suits, the real open water people have to do without. I will mention that at an open-water swim recently, I heard the exact opposite advice:&nbsp;warm&nbsp;up out of the water and don't get in too early if it's really cold. You're going to have to figure out which way works best for you.</p>
                    <h3>You Must Be Strong</h3>
                    <p>Open water is tough. You need to be truly well prepared and have reserves in place. You will not get lucky. You need to be really ready. Practice sighting until you're good at it. Practice swimming with others around you. And get your pool yardage up so you're confident you can be strong in the water. You don't really need to be fast. That will save you only a little time. But you need to be confident and capable of real strength in the water. Be so strong that you can go out at what feels like a relaxed easy pace, sighting often and staying on course. Then the swim works. Go out there weak, zigzagging back and forth, exhausted and scared, and it quickly becomes a horrible experience. I've done both. Pay your dues before you get to the race so you can avoid choosing&nbsp;misery.</p>
                    <h3>The Danger</h3>
                    <p>People have actually drowned doing triathlons. It doesn't happen often, but people can get in trouble. I've twice been through really bad swim experiences. (Both times I quickly found there was a guardian angel in a kayak hovering over me in case I needed help.) One time I had a wet-suit on and one time I had chosen not to wear one so I could have a chance at a medal. Having a wet-suit is better. You can roll on your back and totally relax and still not sink. I think it's a safety issue, and from now on I'm wearing the wet-suit whenever it's allowed.</p>
                </Col>
            </Row>
        </Container>
    )
}