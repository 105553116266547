import React from 'react';
import { Container } from 'react-bootstrap';

export default function Admin() {

    return (
        <Container>
            Hello World
        </Container>
    )
}
