import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import TriathlonIndex from "./TriathlonIndex"

export default function TriRaceDay() {

    return (
        <Container>
            <Row>
                <TriathlonIndex />
                <Col>
                    <h1>Race Day</h1>
                    <p>Actually, I want to back up to a few days before the race. It's a great idea to go over the course before you race it. Last year a student and I went out to pick up our race packets the day before a sprint we were doing. Then we traced the bike route. We found the bad patches where the pavement was a mess. Certain streets had a good side and a bad side. Sometimes that changed half way down the street. We planned exactly how we'd tackle the course. The next day the wind and rain were so bad they cancelled the bike portion of the race for safety's sake. But we were prepared. Two months later the same student and I were in a half Ironman together. Except it was way out of town and we didn't get up there until the night before. So I decided to trust that everything would be OK. So the next day on the bike I'm down in the aero position, which in my case limits how far up the road I can see. And I must admit I let my head drop for a few seconds to relieve the fatigue in my neck. Well you can cover a lot of ground in a few seconds when travelling 20 mph. Suddenly I'm staring at an orange diamond painted on the pavement just beyond my front wheel. Something dark and ominous lay in the middle of the diamond. I didn't have time to swerve. My hands weren't near the brakes. I had enough time for the words, "Oh shit" to form in my head as I braced for impact. That's the last thing I remember until I was in the ambulance. It took a several weeks for the road rash scabs to start peeling off. I was kinda dumb from the consussion for at least a month. And then it was another six months before the pulverized clavical was healed enough to return to training. Draw conclusions please. There's a great deal to be said for a run through of the course.</p>
                    <p>If the event you're doing is a couple of hours or longer you'll also want to eat plenty of carbs in the few days leading up to the race, especially after you worked out. Carb loading works and will improve your performance.</p>
                    <p>Pack up the night before the race. As much as possible have the car ready to go. You will have to get up very early because you want to get there as early as possible to set up transition. Being ready to walk out the door will allow you to sleep as late as possible in the morning. </p>
                    <h3>Race Morning</h3>
                    <p>If you're doing one of the longer triathlons, you should consider getting up early and having a serious breakfast, you know, 600 calories or something, not just a quickie. Do it early enough so you've had several hours to digest it before you start swimming. If it's a sprint, I tend to sleep in and just eat something simple with carbs, a banana or energy bar. I eat in the car as I start driving. Also make sure you get between 8 and 16 ozs of liquid a couple of hours before the event. A study in the April 2010 Journal of Athletic Training found that runners who started a 12-K race dehydrated on an 80-degree day finished about two and a half minutes slower. You can get a little dehydrated overnight. Don't start the race that way.</p>
                    <p>Typically, when you get to a race you'll have to park some distance from the transition area. You'll need to get your bike and gear from the parking lot to the race site. Eventually you're going to want a gear bag that you can strap on like a backpack and then walk, or even ride, your bike to transition. Have your bib visible at this point, you'll probably need it to get into transition and when picking up your timing chip. First thing you do is set up your transition area. Try not to take up too much territory. Space is often limited and you don't want to be a hog. Do not place your bike over someone else's gear. Most people prefer arranging things so the chain side of the bike is away from where they've set up their gear. That way they won't brush up against the bike mechanicals and get grease on themselves. They also run on the "safe" side of the bike as they leave transition. Think through how you'll leave with your bike and set up accordingly. Which direction should the bike be headed? Is it in the right gear? What happens when you leave transition? Do you immediately have a hill to climb. If so, set your gear accordingly. Are the tires inflated properly? Tires do not have to be maxed out. A lower pressure can allow more traction and make for a softer ride. If you've got difficulties, there's usually a bike mechanic on site to help. Once you've got transition ready, you'll also need to get your numbers written on you. Keep in mind that if there's a long line for that and you've brought a marking pen, you can have a fellow racer or friend do it. Usually your timing chip will also need to be picked up the morning of the race. Then deposit your bib number back in your transition area with your running gear.</p>
                    <h3>Sun Block</h3>
                    <p>Avoiding sunburn is a real issue. You can apply it in the beginning, but will it get washed off in the water? You'll be loath to spend transition time on it, but it might be worth it. If you've chosen your clothing well it can provide some protection. It's probably quicker to pull on a couple of arm coverings than apply sunscreen. For gosh sake, on the run wear a hat with a visor. On a race where I know I'm going to be out in the sun for maybe&nbsp;5&nbsp;hours, I'll have the girlfriend spray my shoulders and legs with sunblock as I'm leaving T1.</p>
                    <h3>Plan&nbsp;Ahead</h3>
                    <p> Spend what time you've got left making sure you understand the logistics. Where will you enter the water? What will you be sighting for once you're in the water? Where do you leave the water? What will that look like from the water? How do you get from the water to transition? Where do you leave transition on your bike? How will you return from the bike? How will you exit for the run?</p>
                    <p>I always like having a stopwatch of some sort running during the race. But if you're wearing a wet-suit with arms, the stopwatch is a problem. You'll have to take the watch off before you'll be able to remove the wet-suit. And then put the watch back on when you're ready to get on your bike. The chip timing will be able to give you your pertinent info when the race is over. I've stopped wearing a stopwatch when I'm wearing a wet-suit. It just isn't worth the extra hassle.</p>
                    <p>If at all possible, go to the bathroom. Empty your bowels. If you don't, you may find yourself with a very long transition time because you had to go to the bathroom&nbsp;at T1.</p>
                    <h3>Thirty Minutes and Counting</h3>
                    <p>I then have a bottle I've prepared for my thirty-minute-to-launch hydration and carbs. I'll take a caffeine pill at that point as well. Then it's time to just enjoy the experience. Notice how the first waves go out. Get yourself warmed up. If the water is really cold, it's essential that you get in early and acclimatize yourself to it. Budget 10 minutes for this. I always get in the water before the start, make sure the goggles are working, let some water into the wet suit so I won't get shocked when I start swimming. (Once the water is in the suit it also warms up a little.) Get so you can put your face in the water without going into shock. (Most smaller lakes are not all that cold.) I've mentioned this elsewhere, but putting on goggles first and then the swim cap is a good idea. If the goggles get knocked off, you'll have something anchoring them to you and they'll still be there. Know how to put your goggles on in water over your head. Practice this beforehand. You'll have to peddle with your legs to stay afloat&nbsp;while you set the goggles properly.</p>
                    <h3>The Starting Line</h3>
                    <p>I can't speak for everyone, but I always feel a little nervous about the swim. If you're feeling that way too, all I can offer  is that you're probably not alone. But there are people looking after you when you're in the water. You just have to believe it will be OK. How you start your swim is up to you. It's easiest to draft and keep on course  if you're in the pack. But you can get knocked around a little bit. If you prefer, you can stay to the outside. But then you're on your own for sighting and staying on course. If you need to rest, rest. Spend a minute on your back if you need to collect your thoughts. Know what you'll say to yourself if you start to feel panicky. Seriously, have a plan for difficulties. If you just stick it out, at some point you'll be walking out of the water and realizing you did it.</p>
                    <h3>T1</h3>
                    <p> Getting to T1 is sometimes a long journey, and sometimes the surface you're running on hasn't been swept clean of stones and debris. One thing you can do is plant some sandals along the fence as you get out of the water. If you want to make sure no one borrows your sandals, you can take an old pair of running shoes that you'll never use again and cut off the backs so you can slip into them easily. As you're jogging to transition, get your wet-suit off down to your waist. The only other issue is  your goggles and cap (consider inserting them in the suit arms) or wait until your within sight of your towel. Whatever you do, don't take them off early and then lose them. You'll probably never see them again and if you do you'll probably be penalized for littering. Your transition should be a well rehearsed sequence. If you're wearing a suit, pull it off your legs. Dry your feet off if need be. If you'll be wearing socks, put them on. Bike shoes if they're not already clipped in. Helmet with chin strap in place, glasses, whatever. Grab your bike and jog to the exit. Most triathletes have taught themselves to run with the bike by holding the seat with the near hand. The side away from the gears is preferred. You cannot mount the bike until you've crossed the official exit line. You should have practiced your mount. And you're off. Get up to speed and relax a second.</p>
                    <h3>Pacing Yourself</h3>
                    <p>Keep in mind that a triathlon is one long race. If you go all out on the swim, your bike leg may suffer. Save 2 minutes on the bike with your best effort ever and you may lose 5 minutes on the run because you're wasted. Race within yourself until the last miles of the run. It's essential to hydrate, and the bike leg is the easiest time to do so.  It's also easier for your body to deal with digestion on the bike than on the run. But all this needs to be carefully thought out. You can drink too much water and dilute your electrolytes so completely you're in trouble. Sodium and Potassium are the  essential electrolytes that have to be included in your hydration. On a long race over an hour and a half, a modest input of carbs can help. but you can work so hard at ingesting carbs that you down more than you can digest. Next thing you know you're stomach will be cramping. Somewhere down the road you could find yourself by the side of the road vomiting or having diarrhea or both. Your fueling needs to be carefully thought out in advance. Months of training and preparation can be ruined by not knowing what you're doing when you fuel during a race. The best thing about the bike fueling is it's your own concoction. Unless you carry your own fluids, none of this is possible  on the run.</p>
                    <h3>Lowering Your Body Temperature</h3>
                    <p>This requires some amazing forethought, but if you can manage to keep your liquids cold on a hot day, that will help. A 2008 study in Medicine &amp; Science in Sports &amp; Exercise showed that you can go longer on cold liquids. They lower your core temperature. If someone offers you a slushie on one of those dangerously hot days we sometimes have to race in, thank your lucky stars.</p>
                    <h3>On The Bike</h3>
                    <p>Be aware of your position on the bike. If you've slowed down, it's not so important. But the faster you go the more wind resistance is a huge factor. Keep low on the bike. And just peddle, to the best of your abilities, based on your training. If you haven't done the training, there's nothing you can do at the last second to fix it. Just do your best and hang in there. Make sure you understand the rules on drafting. The last thing you need is a time penalty. Also have a plan for drinking, small manageable amounts on a continuing basis. What trigger can you use to remind yourself it's time to drink?</p>
                    <h3>When Things Get Tough</h3>
                    <p>If you consider quitting because it's hard, be ready with your reasons for doing the race. It's supposed to be hard. If it wasn't hard, no one would bother. This is what you signed up for. Whatever pain you're feeling is just pain. Just nerve endings firing. If you're not actually damaging yourself, don't quit. Pains that grow increasingly more painful in your legs or feet mean, yeah, this could be damage being done. This is not an easy thing you're doing. It is possible to fail. I've learned the hard way that too much macho can leave you with problems that last for years. There are medical tents if you need a second opinion. There will be other races. I've DNFed in triathlons, twice. And then again in a long distance swim. It doesn't feel good. But it's just a race, and life goes on. Don't ruin yourself because you were too bull-headed to call it.</p>
                    <h3>T2</h3>
                    <p>As you near the finish of the bike leg, you might be smart to ease off a little. Give the legs a chance to begin the transition. Sitting up-right on the bike can cause you to use muscles closer to the running muscles. That allows the blood flow to begin to shift. You can't ride your bike into transition. Dismount before you reach the demarcation line. Rack your bike. You are not allowed to just dump it on the ground. There will be a penalty if you do. Helmet off. Bike shoes off. Running shoes on. If you're smart you'll have set up your shoes so they have elastic laces and don't need tying. Hat on. Snap your bib holder in place as you start running. </p>
                    <h3>The Run</h3>
                    <p>If your legs feel like telephone poles when you begin to run, just do your best and keep at it. In time the feeling goes away. You'll have water stations available during the run. Typically there will also be a commercial sports drink available. Which drink is offered has nothing to do with what your body needs and everything to do with business sponsorship. Know what is in the sports drink and whether that's something you want. If you're not sure, water taken with an occasional electrolyte tab is an alternative, or carry your own. Feel free to walk during drinking. You won't lose a lot of time and choking on water really will slow you down. Ideally you will have handled much of your hydration/fueling needs while on the bike and you won't need a lot on the run. Keep in mind it takes at least 20 to 30 minutes for water to get from your stomach into your system. Unless you're dangerously dehydrated, the water you drink in the last 20 minutes probably isn't going to have a huge effect on your time. For me, the run is the fun part of triathlon. You find yourself running with people and chatting, encouraging each other. Slowly see if you can pick up the pace. Finish strong.</p>
                    <h3>Your Cheering Section</h3>
                    <p>A word about family watching the race. First, it's really nice to have them there. And it's nice to have someone to drive you home. I've driven myself home a number of times and it's hard. If the race is a long one, you're in no condition to drive a car. You're fatigued, your legs may be cramping. It's also nice to have someone there in case you end up in the hospital. I once crashed on the bike and was an absolute wreck. Thankfully, my partner Joy was with me. She gathered all my equipment, got it in the car and drove me home. There is no way I could have done any of that for myself.</p>
                    <p>Trouble is, it's not a great spectator sport. People go out in a lake, and you can't see what's going on. Transition is mildly interesting, but then they're gone again and you won't see them again for maybe an hour or so. In an Ironman it may be six hours before they return. Another transition and the racer disappears again. Another long wait. It's boring. It's incredibly nice seeing your loved one at the finish line, but gee whiz. It's a lot to ask. Only trouble is, it's really empty when the family isn't there for the finish. If they do show up, appreciate it.</p>
                </Col>
            </Row>

        </Container>
    )
}