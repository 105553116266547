import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'

import TwoCentsIndex from './TwoCentsIndex'

export default function Marines() {

    return (
        <Container>
            <Row>
                <TwoCentsIndex />

                <Col>
                    <h1>The Marines</h1>
                    <Row>
                        <Col>
                            <div style={{ width: '50%' }}>
                                <Image
                                    src='/TwoCents/ChrisOCS.jpg'
                                    alt='true'
                                    align='left'
                                    fluid='true'
                                    className='picture'
                                />
                            </div>
                            <div className='pull-right'>
                                <p>I'm not sure why I want to write about this, except that it's been a profound experience for me. I recently spent a few days at Quantico Marine Base, Virginia, steeped in all things Marines and watching my son graduate from Officer Candidate School. That's a picture of Chris. He and his fellow candidates had just spent an hour in the rain with the temperature in the 30s. That was evidently their choice. The schools executive officer had asked them if they'd like to do the ceremony indoors. They'd declined. So parkas were handed out to all the parents and girlfriends. We then huddled under our umbrellas in our winter coats, trying to catch a glimpse of what was happening through a forest of umbrellas. And the rain poured down, and the candidates marched.</p>
                                <p>Chris just finished up Notre Dame Law School in the spring (2013). About a year before that he found himself completely unenthused by the idea of joining the corporate world or a law firm. A friend suggested joining the Army JAG Corps. You spend a couple of weeks learning how to salute and, bingo, you're an officer. Chris found the idea off-putting. But the Marines were different. They expect any Marine wanting to call himself an officer to go through the very special hell that is Marine Corps OCS. So even if your specialty is going to be as a Supply Officer, you first are trained as an infantryman. Chris was heavily into fitness and the idea somehow took root. So he started working out with the Marine Corps team at Notre Dame. When I heard what was going on, I was rather astonished. I totally had not seen this coming. But it was also touching. My father was a Marine pilot in World War II. The idea of a connection across the generations brought all sorts of memories of my dad back to me.</p>
                            </div>
                        </Col>
                    </Row>
                    <p>So for the next year his Marine Corps training almost seemed more important to Chris than graduating with a law degree. I worried that his priorities were out of whack and thought it odd that he felt he had to get a perfect score on the fitness test. Even after he'd graduated and was preparing for the New York Bar Exam I worried his preparation for that was getting short-shrift because of his focus on getting his three mile run time down to 18 minutes. Surely the Marine Corps doesn't care how fast their JAG officers can run. Except they do. They're funny that way. You will not run across fat Marines or a Marine with their shirt not tucked in. Such possibilities have been removed from their DNA.</p>
                    <p>Somehow it all worked out. Chris graduated from Notre Dame. Then he heard that he'd been accepted for OCS. At first I couldn't figure out why he'd been so worried about it. Then I learned that there had only been three slots for JAG candidates and a hundred lawyers had applied. Really? My boy, Chris? I mean, I like the lad a lot, but top 3%? It didn't compute. Except his acceptance into the JAG Corps would be contingent upon him passing the New York Bar Exam. And he wouldn't hear if he'd passed or not until half way through OCS. If he didn't pass his slot in the JAG Corps would disappear. But he still might be offered a commission as a regular infantry officer. (And, of course, if you're a dad, you sort of would prefer you child in the JAG Corps instead of getting shot at.) So it was all a bit complicated.</p>
                    <p>Chris headed off for OCS in September. They don't have computers or phones or free time at OCS so you have limited direct news. Wanting to understand what Chris was going through, I spent most of my free time over the next couple of months reading on the OCS website about the process they put them through. Just getting in is tough, there's a forum where people wanting to become candidates talk about how to get into the program. Some have been trying for years. So you don't get in unless you're in ridiculous shape and they think you have potential. And then they run them ragged for ten weeks. In the end, fully 30% don't graduate. There's a page on the OCS web site where you can see pictures of your child's company going through their paces. I spent a lot of time going through the images there, trying to find shots of Chris. But when the candidates are covered in mud or camouflage paint it's sometimes hard to tell who's who. So you stare at the obstacle courses and can't imagine your child going through all this. I'm told it's the toughest training of any of the services. I can believe it.</p>
                    <div className='text-center'>
                        <Image
                            src='/TwoCents/OCSWet.jpg'
                            alt='OCSWet'
                            fluid='true'
                        />
                    </div>
                    <br></br>
                    <p>It's almost like something out of the movies. Long marches with 60 pounds on their back. Carrying huge logs. Crawling through water. Sergeant Instructors in their face. Obstacle courses with very high obstacles. And sometimes on the weekends they get 24 hours liberty and the girlfriend heads down from New York City on the train.</p>
                    <Row>
                        <Col>
                            <Image
                                src='/TwoCents/OCSHeights'
                                fluid='true'
                                alt='OCSHeights'
                                align='right'
                            />
                            <div className='pull-left'>
                                <p>I also did a lot of reading about the Marine Corps in general. The training the enlisted men go through is an ordeal and a half. But it seems to pay off. The Corps has esprit. They believe in themselves. And when they have to fight, they dominate.</p>
                                <p>Then half-way through OCS we learned that Chris had passed the New York Bar Exam with flying colors. Maybe he'd be able to pull off the entire crazy jig-saw puzzle.</p>
                                <p>If a parent wants to attend graduation, they have to, at some point, buy airline tickets, knowing as they do so that the child may not make it. It's an act of faith. You also write your candidate a lot of letters just because everyone says it helps moral. And you just hope and hope until you finally get a call saying that the tests are over and they'll be graduating in a couple of days.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className='text-center'>
                        <Image
                            src='/TwoCents/OCSInspection.jpg'
                            alt='OCSInspection'
                            fluid='true'
                        />
                    </div>
                    <br></br>
                    <p>I flew in on a Sunday. The next morning I got up at 6:30 a.m. to watch the Battalion Commander's Motivational Run. The candidates and the staff go out for a five mile run. Whoop-de-do. Except it's kind of cool. Which triggers a memory. Because it was freezing out there. It was around twenty degrees that morning, and here comes a column of close to 200 future officers and instructors in their shorts, their legs bright pink from the cold, singing out the usual cadence songs. You just sort of watch in awe.</p>
                    <p>Later that morning the OCS Commanding Officer talked to the parents. The question came up of why put the candidates through all the pain and suffering. Best I can reconstruct his answer, it was about being able to make a decision, to do the right thing under incredible stress. So they deprive them of sleep and work them to exhaustion, and then, when they're hardly able to keep going, they ask them to make decisions. And if they can't make decisions, if they freeze up, the Corps knows that putting the lives of other Marines in such hands would be a terrible mistake. They want people of character, and they desperately need to weed out the ones who lack it.</p>
                    <Row>
                        <Col>
                            <Image
                                src='/TwoCents/OCSMarching.jpg'
                                alt='OCSMarching'
                                fluid='true'
                                align='right'
                            />
                            <div className='pull-left'>
                                <p>Part of the program is to get the candidate to respond quickly when given an order. Do it now and do it fast. So discipline is important. But they also want them to do the right thing. I heard tell of one candidate dropping out of formation on a long march to walk with an injured candidate. The Sergeant Instructor was all over him yelling at him to get back in formation, screaming in his face. The candidate ignored him because sticking with your buddy felt like what a Marine should do. The Sergeant Instructor finally gave up. The Commanding Officer smiled when asked about such moments. "We don't necessarily want them to obey every order. But that's the worse we ever do to them, we yell at them. If you can't do the right thing just because somebody's yelling at you, what good are you?"</p>
                                <p>Someone else asked about women in the Marine Corps. The C.O. mentioned serving in Iraq. He had all sorts of women serving on what amounted to the front line whether the Pentagon wanted to call it that or not. And all of those women performed with great courage and skill. There was a platoon of women in Chris's class, who went through the same grueling challenges as Chris did and who were now graduating to become Marine Officers. In addition three women recently graduated from the specialized and intensely difficult training to become a Marine Infantryman, matching every step of the way what the men had done. As far as the C.O. was concerned, it was a done deal. Women belonged wherever they could qualify.</p>
                            </div>
                        </Col>
                    </Row>
                    <p>All the talk about honor and duty doesn't sound modern or hip. And it's not. The Marines expect those joining up to adopt the culture and beliefs of the Corps. As the C.O. put it, they joined us. We didn't join them.</p>
                    <p>During my visit I got to meet a bunch of the fellows in Chris's platoon. They had shown up for OCS in terrific shape and after ten weeks of intense pressure, they were in even better shape. There's nothing average about any of them. They seem humble and very respectful. (You hear Sir and Ma'am a lot, which sound very strange in this day and age.) But they all seem to be several inches taller than normal and they just exude confidence. It was impressive.</p>
                    <p>That night I got to visit the Basic School, that's the next six months in Chris's training. Now that the Corps knows that these candidates are worth keeping, it's time to really train them in warfare. The rooms are a step up. Instead of barracks, it's now more like a college dorm. Oh, and I got to play with some pretty expensive machine guns. Kinda neat to hold one of those in your hands.</p>
                    <p>And the next morning was the graduation parade in the rain, followed by the commissioning ceremony at the Marine Corps Museum.</p>
                    <div className='text-center'>
                        <Image
                            src='/TwoCents/OCSCommissioning.jpg'
                            alt='OCSInspection'
                            fluid='true'
                        />
                    </div>
                    <br></br>
                    <p>In the six months since, Chris has been training at the Basic School. He's now an expert with a rifle. He's learned tactics. He's spent days on end out in the rain sleeping in holes and doing field exercises. He's having one amazing adventure. How all this relates to being a lawyer, I'm not sure I can explain. But I rather admire the Marine Corps attitude on such things. If you ever meet a Marine Officer, or any Marine for that matter, be very impressed.</p>
                    <p>The only complaint I can think of regarding the Marines is that they're poor. The Pentagon doesn't pay them much, and then they get charged for things. They pay for their haircut at bootcamp. They pay for their uniforms. They pay for their own field rations (MREs). Chris got shipped out to spend a couple of days with the fleet and they charged him for the meals on the boat. Some of the fat cats in Washington need to get a clue.</p>
                    <p>I find the Marines fascinating. They are warriors. They have been indoctrinated, brain washed if you will, to believe they are willing to die for their fellow Marines and the honor of the Corps. They have been put through hell to earn the title of Marine, and they know that their fellow Marines have been through that same hell. They believe they are brothers. It doesn't matter if you want to fly a helicopter, or join the JAG or be a supplies officer, you go through most of the same intense training as you would if you wanted to lead infantrymen.</p>
                    <p>The Marines have a motto: <i>Every Marine a rifleman.</i> If you're a genius but you can't pass the marksmanship test, you're on your way home. You do not become a Marine. And for what it's worth, the bare minimum score for a Marine would be considered an Expert score in the Army. So if you attack some Marines moving supplies, good luck. If you've got a Marine flying a helicopter to pick up a squad of Marines, those are his brothers down there, and he'll go through hell to make that pickup.</p>
                    <p>The training is widely acknowledged to be the toughest of any military force. It's long and it's hard. It ends with something called the Crucible. And by the time the Marines are finished they've been toughened up beyond normal understanding, they are indifferent to pain, and they are intensely proud of what they've accomplished. When they say, <i>The Few, The Proud, The Marines,</i> they mean it.</p>
                    <p>It makes for the best fighting force on the face of the earth.</p>
                    <p>In trying to understand all this, I've read a lot of books about the Marines lately. And time and again I've thought to myself, wow, what a class organization. Here's an example: Marines were waiting in Kuwait for the attack on Iraq. One night they didn't have enough food in the chow tent. The officers and non-coms were told to find food elsewhere, only privates and lance corporals would be dining that night. When I shared the story with Chris, he shrugged, "Of course, you take care of your men."</p>
                    <p>One more story. Back around 2000 an Army soldier beat his roommate to death with a baseball bat because the roommate was gay. Hoping to discourage such behavior, the Pentagon sent out a document that they wanted read to all US military personnel.</p>
                    <p>A Marine Commanding Officer gathered his troops together. He seemed rather annoyed with the official document, but he felt he should say something to the Marines. Quoting from the book <i>Keeping Faith</i> by John & Frank Schaeffer, here are the comments of the Marine CO:</p>
                    <p>"The Pentagon wants me to tell you that you are not to beat anybody to death in their rack with a ball bat!"</p>
                    <p>"Yes, sir!"</p>
                    <p>"All such activities are to cease as of now! All you need to know about the Marine next to you is that he or she went to boot camp same as you and earned the title!"</p>
                    <p>"Yes, sir!"</p>
                    <p>"All you need to know is that you are all United States Marines! That goes for the male and female Marines and whatever else the fuck you call yourself on your own time! We all earned the same title! If there is a Marine standing next to you, he or she got handed the eagle, globe, and anchor same as you after completing the Crucible! Good to go?"</p>
                    <p>"Yes, sir!"</p>
                    <p>"Did he, she, it, whatever, earn the title?"</p>
                    <p>"Yes, sir!"</p>
                    <p>"Are all Marines brothers?"</p>
                    <p>"Yes, SIR!"</p>
                    <p>"That's all you need to know about <i>that!"</i></p>
                    <p>Everything about the Marine Corps is impressive. The people I met are not getting wealthy. For them love of country and love of the Corps are palpable realities and to their minds worth enormous sacrifice. I came away marveling at what I'd seen and astonished that my son had become part of such a tradition. I wish my dad could have seen this. I don't think I ever fully understood what the Marine Corps meant to my dad. I think I get it now.</p>
                    <p>Had enough? You can stop reading any time. But there are some fascinating quotes about the Marines. Here are a few:</p>
                    <p><i>For all of those that have son's or daughter's at bootcamp let me pass on what I found. Let me give you a little back ground first. When my son left home he had no motivation, he was lazy, slobby, no pride, no self worth. This is the boy that got off the bus March 18th at Parris Island. The man that I met on Thursday for parents day is AWESOME. There is no way I can describe to you all the difference. He looks different, he walks different, he talks different, he has such a sense of bearing and pride all I could do was look at him in awe. Oh yes, the training is hard, what he went through is unimaginable to any one that has not been there. They are definitely taught to be Warriors. Let me tell you the surprise of what else they are taught. My Marine son has better values, better morals, better manners than any one I know. It is so much more than Yes Sir, Yes Mam...so much more. He cares about how he looks, he cares about what he does, and its not a boastful, bad ass thing. He is a true gentleman. I saw patience, and a calmness in him that I have never seen. I could never express my gratitude enough to the Marine Corps for what they have given my son. I know this, I have an 11 year old Devil pup still at home. When the time comes for his turn if I had to I would take him kicking and screaming all the way. Although I'm sure that will not happen. The hero worship I see in my younger sons eyes for his Marine brother tells me I will have two Marines in the family, and I will be one very proud mother.</i><br></br>"Cybil", Mother of a Marine writing to the myMarine Group</p>
                    <p><i>The safest place in Korea was right behind a platoon of Marines. Lord, how they could fight!</i><br></br>MGen. Frank E. Lowe, USA; Korea, 26 January 1952</p>
                    <p><i>I come in peace, I didn't bring artillery. But I am pleading with you with tears in my eyes: If you fuck with me, I'll kill you all.</i><br></br>Gen. John J. "Black Jack" Pershing, USA; 12 February 1918</p>
                    <p><i>The United States Marine Corps, with its fiercely proud tradition of excellence in combat, its hallowed rituals, and its unbending code of honor, is part of the fabric of American myth.</i><br></br>Thomas E. Ricks; Making the Corps, 1997</p>
                    <p><i>I have just returned from visiting the Marines at the front, and there is not a finer fighting organization in the world!</i><br></br>General of the Armies Douglas MacArthur; Korea, 21 September 1950</p>
                    <p><i>The Marines I have seen around the world have the cleanest bodies, the filthiest minds, the highest morale, and the lowest morals of any group of animals I have ever seen. Thank God for the United States Marine Corps!</i><br></br>Eleanor Roosevelt, First Lady of the United States, 1945</p>
                    <p><i>We have two companies of Marines running rampant all over the northern half of this island, and three Army regiments pinned down in the southwestern corner, doing nothing. What the hell is going on?</i><br></br>Gen. John W. Vessey Jr., USA, Chairman of the the Joint Chiefs of Staff during the assault on Grenada, 1983</p>
                    <p><i>Marines I see as two breeds, Rottweilers or Dobermans, because Marines come in two varieties, big and mean, or skinny and mean. They're aggressive on the attack and tenacious on defense. They've got really short hair and they always go for the throat.</i><br></br>RAdm. "Jay" R. Stark, USN; 10 November 1995</p>
                    <p><i>Don't you forget that you're First Marines! Not all the communists in Hell can overrun you!</i><br></br>Col. Lewis B. "Chesty" Puller, USMC rallying his First Marine Regiment near Chosin Reservoir, Korea, December 1950</p>
                    <p><i>I love the Corps for those intangible possessions that cannot be issued: pride, honor, integrity, and being able to carry on the traditions for generations of warriors past.</i><br></br>Cpl. Jeff Sornig, USMC; in Navy Times, November 1994</p>
                    <p><i>My only answer as to why the Marines get the toughest jobs is because the average Leatherneck is a much better fighter. He has far more guts, courage, and better officers... These boys out here have a pride in the Marine Corps and will fight to the end no matter what the cost.</i><br></br>2nd Lt. Richard C. Kennard, Peleliu, World War II</p>
                    <p><i>You cannot exaggerate about the Marines. They are convinced to the point of arrogance, that they are the most ferocious fighters on earth- and the amusing thing about it is that they are.</i><br></br>Father Kevin Keaney, 1st Marine Division Chaplain, Korean War</p>
                    <p><i>There was always talk of espirit de corps, of being gung ho, and that must have been a part of it. Better, tougher training, more marksmanship on the firing range, the instant obedience to orders seared into men in boot camp.</i><br></br>James Brady, columnist, novelist, press secretary to President Reagan</p>
                    <p><i>By their victory, the 3rd, 4th and 5th Marine Divisions and other units of the Fifth Amphibious Corps have made an accounting to their country which only history will be able to value fully. Among the Americans who served on Iwo Island, uncommon valor was a common virtue.</i><br></br>Admiral Chester W. Nimitz, U.S. Navy</p>
                    <p><i>The Marine Corps has just been called by the New York Times, 'The elite of this country.' I think it is the elite of the world.</i><br></br>Admiral William Halsey, U.S. Navy</p>
                    <p><i>I can't say enough about the two Marine divisions. If I use words like 'brilliant,' it would really be an under description of the absolutely superb job that they did in breaching the so-called 'impenetrable barrier.' It was a classic- absolutely classic- military breaching of a very very tough minefield, barbed wire, fire trenches-type barrier.</i><br></br>Gen. Norman Schwarzkopf, U. S. Army Commander, Operation Desert Storm, February 1991</p>
                    <p><i>I am convinced that there is no smarter, handier, or more adaptable body of troops in the world.</i><br></br>Prime Minister of Britain, Sir Winston Churchhill</p>
                    <p><i>The deadliest weapon in the world is a Marine and his rifle.</i><br></br>Gen. John "Black Jack" Pershing, U.S. Army Commander of American Forces in World War I</p>
                    <p><i>Do not attack the First Marine Division. Leave the yellowlegs alone. Strike the American Army.</i><br></br>Orders given to Communist troops in the Korean War; shortly afterward, the Marines were ordered to not wear their khaki leggings.</p>
                    <p><i>The American Marines have it [pride], and benefit from it. They are tough, cocky, sure of themselves and their buddies. They can fight and they know it.</i><br></br>General Mark Clark, U.S. Army</p>
                    <p><i>If I had one more division like this First Marine Division I could win this war.</i><br></br>General of the Armies Douglas McArthur in Korea, overheard and reported by Marine Staff Sergeant Bill Houghton, Weapons/2/5</p>
                </Col>
            </Row>
        </Container>
    )
}