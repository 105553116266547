import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'

import TriathlonIndex from "./TriathlonIndex"

export default function TriBike() {

    return (
        <Container>
            <Row>
                <TriathlonIndex />
                <Col>
                    <Row>
                        <Col>
                            <div style={{ width: '50%' }}>
                                <Image
                                    src='/Triathlon/TriBike.jpg'
                                    fluid='true'
                                    alt='TriBike'
                                    align='left'
                                    className='picture'
                                />
                            </div>
                            <div class='pull-right'>
                                <h1>Bike</h1>
                                <p>The bike section of the race is a test of strength, endurance and how much money you've spent. I'm being cute here. Mostly it's about your conditioning. But maybe 10% of your result will depend on your equipment. On the other hand, it has nothing to do with tactics or jockeying for position. Drafting is usually not allowed. It's all up to you.</p>
                                <p> In the picture to the left, take a look at the position I'm in. Not good. (I'm hoping there was some reason for it. Maybe I was slowing down for the transition.) But look at how upright I am, how much front I'm presenting to the air. This is something I talk about when I discuss swimming. The amount of resistance created by the medium you're moving through (air, water, whatever) is based on three things: the frontal size of the moving body, the density of the medium and finally the <strong><em>square</em></strong> of the speed with which you're moving.  Obviously, in the water you're not moving all that fast, but the water is so much denser than air that the resistance is huge. When you're running at 8 miles an hour the air resistance is modest because air isn't terribly dense and at the square of 8 miles an hour just isn't that great (8*8=64). However, when you hop on a bike and bump your speed up to 25 miles an hour, the air resistance becomes significant (25*25=625, about 10 times greater than at 8 miles an hour). Think of a sail on a boat heading into the wind. When that sail is lowered onto the boom, it doesn't catch a lot of wind. The wind won't push back against the boat. Lift the sail and it does. I'm a lifted sail in this picture. If I got flatter and lower on the bike, I'd be a sail sitting on the boom. Cutting down the area that air bumps up against, becoming more aerodynamic, really does matter.</p>
                                <p>That bike in the picture is not a tri bike. Notice the black diamond attached to the handlebars. That's a fake aero bar. There are elbow pads you can rest on and you hold onto the black diamond. It allows me to get very low and narrow on the bike and present as little front as possible to the wind. (The downside is the crick that develops in your neck as you stare down the road.) The trouble with this "fake aero bar" arrangement is that every time I need to shift gears I have to take a hand out of position and move it onto the shifters (where you see them positioned in the picture). So a true tri bike has aero bars that go straight out with shifters attached to the ends where your hands rest. The brakes however, are a few inches away over on the handlebars. You have to leave the aero position to use them. Since in a triathlon you're normally not allowed to ride in packs and you're racing for gosh sake, you'll rarely need to brake. So that's not a serious drawback. But the shifting problem is huge.</p>
                                <p>OK, you ready for this? Here's this season's bike:</p>
                                <div className='text-center'>
                                    <Image
                                        src="/Triathlon/TriBlueTriad.jpg"
                                        fluid='true'
                                        alt="Blue Triad Tri Bike"
                                        align='center'
                                    />
                                </div>
                                <p> I'm now riding a Blue Triad with the Shimano Ultegra group (but not the Zipp wheels). And it's a truly amazing bike. However before I convince you to go out and spend gobs of money on one of these, I need you to know the negative on Tri bikes. It's something I knew before I even bought it, but I'm a freaking coach and I figured I ought to go the whole nine yards. But it's a very specialized bike. It's for learning how to be OK with the aero position and being able to hold that shape with your neck begging for relief for hours on end. It's about seeing only a slim portion of the road ahead of you because your head just can't lift high enough. And that makes it more dangerous than a road bike. (In the midst of a half Ironman this summer I was in the aero position and suddenly realized I was seeing an orange diamond painted on the road with something dark in the middle. A millisecond later I hit what I assume was a hole or a grate. The next thing I can remember I was in an ambulance. As others were finishing the race I was cracking jokes with the doctors in the hospital.)</p>
                                <p>A tri bike is about racing. And if you're willing to pay the price you really can go a couple of miles per hour faster on one of these. But it's not a fun bike. You're not going to be enjoying the scenery. You're not going to be chatting with friends. Riding in a peloton is out of the question. Not great maneuvering control because of the aero position. The breaks aren't quickly at hand. In sum, the aero position is not a good position for normal bike riding. It's just a very different experience. If you do triathlons, you find yourself wishing you had two bikes. One for going out for a ride with friends and one for racing. (Come to think of it, now I do have two bikes.) Actually I wish I had four bikes. One for racing, one for rides, one old clunker that no one will want to steal for doing errands, and one frame plus back wheel for the trainer.&nbsp;If you can only afford to own one bike, I wouldn't recommend a tri bike. Get a regular road bike that you can enjoy in a variety of circumstances. It'll do just fine in the triathlon. But if you're filthy rich, well if you're filthy rich you don't need my advice on anything.</p>
                                <p><strong>Good things about Tri bikes:</strong><br></br>
                                    I rather like being able to rest on my elbows.<br></br>
                                        I love the water bottle that sits between my aero bars.<br></br>
                                            When going fast enough, you really do cut back on wind resistance when you're that low on the bike.</p>
                                <p><strong>Bad things about Tri bikes:</strong><br></br>
                                    The crick in the neck is a total drag.<br></br>
                                        You won't be able to see as far down the road.<br></br>
                                            You're not as maneuverable as you are on a regular road bike, therefore you've increased your risk of an accident.<br></br>
                                                        It's not a great bike for going out with friends and enjoying the scenery and camaraderie. </p>
                                <br></br>
                                <h3>Buying a Bike</h3>
                                <p>Oh, gosh, the money you can spend. Anywhere from $1000 to $18,000. And every $1000 you spend will probably shave a few seconds off your race time. Because when you're going above 20 miles an hour, the really good bikes really are faster. They slide better through the wind. They transfer your efforts into forward propulsion better. The first thing you should do is not worry about it. Ride on any old bike you can get your hands on. Give yourself a season of racing to find out if you really want to keep at this. A tri bike is not a good general purpose bike. It's for racing, and only one kind of racing at that. Don't buy one unless you live to race and can go fast enough to make it worth your while. The slower you're going the less aerodynamics affect your time. A beginning bike rider on a $10,000 bike is just plain silly and a huge waste of money. (Bike riders have a name for someone riding 12 miles an hour on a super expensive bike: "All show and no go." It's not a compliment. The drawback to buying the Blue Triad is that now I have to become worthy of the bike. On the other hand, that motivation is part of why I did it.) I think you get the most bang for your buck somewhere between $2,000 and $3,000. Leave the $10,000 bikes for the pros trying to make a living off of it. (And they'll probably get their bikes for free.)</p>
                                <p>A bike can be broken down into two expenses, the frame and the components. Generally, the cheaper frames are aluminum or steel. The more expensive frames are carbon fiber. However, all carbon fiber is not created equal. You can find a metal frame that's better than a carbon frame. A good bike will be lighter than a cheaper bike. It will also translate your efforts into movement by being stiffer while, conversely, dampening some of the road shock you can experience from jolts on the bike. The components are gears and shifters and brakes, and usually a bike maker will pick a package of components (called a group) from one of the component companies at a price level appropriate to the frame they're selling. If it's one of their cheaper frames, they'll use a cheap group. If it's their top-of-the-line frame, they'll use a top-of-the-line group. Components are judged on their lightness and precision. Shifting with really good components feels very different than with cheaper ones. I sometimes find myself thinking the really good stuff shouldn't be so ridiculously expensive. But the bike industry doesn't seem to care what I think.</p>
                                <p>The only bike you can't use in a triathlon is a recumbent bike. (Although I'm not sure a unicycle would qualify either.) More in the <a href="/Triathlon/TriRules.html">rules section</a>. One of my students is about to buy a bike. She's putting comfort over speed and getting a road bike instead of a tri bike. I think that's an excellent decision. Oh, and when you're getting fitted for a bike? The bike store people may want to put you in&nbsp;a very aggressive&nbsp;position, low and aerodynamic on the bike. There are reasons for this, but don't let them put you so low you're not comfortable. Sometimes these guys forget that not everyone is flexible enough for that. And don't let yourself get so low that you lose power. An extremely aggressive position can gain an aerodynamic advantage, but at the price of a loss of power&nbsp;from the legs.</p>
                                <h3>Clipless Pedals </h3>
                                <p>Back in primitive times, when the bike first rolled itself upon the earth, you just put your shoe on the flat platform of the pedal and pushed. Then someone figured out that if they could attach the foot to the pedal, they could not only push on the downstroke but pull up on the other half of the pedal rotation. (Actually recent testing indicates that the best bicyclists don't really do much lifting on their peddles, but who knew?) So a cage was created which held the shoe against the pedal. They called this a clip. It was an imperfect solution and some years later someone devised a way to lock the shoe into the pedal. A twist of the foot would unlock it, but during normal pedaling the shoe and pedal became one. They called this a clipless pedal. Since you're clipped into the pedal, this seems like a strange way to describe it, but such is life. Even if you're not doing a lot of pulling up, locking the foot into the pedal allows for a much firmer, faster stroke. And think about what's happening if you're not at least thinking in terms of pulling up on the peddle. If not lifting, is your foot resting on the pedal? If so it's adding weight and in-effect pushing slightly against the foot doing the work. It could even be actively pushing down on what should be the foot's upstroke. People do all sorts of strange things when they're not conscious of the possibilities. </p>
                                <p>Pulling up like this doesn't come naturally. It's something that you'll have to work on. Probably the best way to do so is to pedal with one foot at a time. (Easier done on an indoor trainer than out on the street.) Focus on your pedal motion, alternating between pushing and pulling. On the bottom of the stroke pull back as if you're scraping mud off your shoes. Smooth this out and you'll turn into a better, stronger rider. (As I mentioned above, everyone talks about learning how to lift up on the peddle as a skill, except when they tested tour riders, they weren't really pulling up much. But at least they weren't pushing down.) There are two type of clipless pedals, one for mountain bikes and one for road bikes. For racing on a tri bike you'll be better served by the road bike version.<br></br></p>
                                <h3>Saddles</h3>
                                <p>This is a uniquely personal decision. The right saddle can make a huge difference in your level of comfort on the bike. There is no one best saddle. It's whatever you find most comfortable. You'll need to either find a bike store that allows you to take different ones home to try and return until you figure out what works best for you. Or spend an hour getting "fitted" for one. I went in and got set up on a stationary bike and for an hour we pulled saddles on and off. This service meant that the $40 saddle cost me $110. But the new saddle makes it easier to enjoy my rides and that's worth a whole lot. The saddle I'm using now has a groove down the middle so the saddle doesn't push up between my legs. That used to get sore and now it doesn't. I like the only contact between me and the saddle being my sit-bones.</p>
                                <h3>Water Bottles</h3>
                                <p>Traditional water bottles are a drag. You have to reach down, you have to lift your head to drink, you have to get it safely back into the holder. While racing I once missed the holder as I was putting putting the bottle back. It hit the ground and I had to turn around, dismount, and retrieve the bottle. Not what you'd call ideal racing style. But even it you don't drop the bottle, the reaching and the tilting slows you down. There's an alternative system called the <a href="http://www.profile-design.com/profile-design/products/hydration/all-hydration/aerodrink.html" target='_blank' rel="noopener noreferrer">Profile Design Aerodrink</a>. That's a water bottle designed to sit between your aero bars. It includes a tube sticking out the top that you can drink from. Much easier than water bottles, which means you're more likely to hydrate. There's also something called the <a href="http://www.invisciddesign.com/" target='_blank' rel="noopener noreferrer">Speedfil Hydration System</a>:  a fluid container which attaches down around where your water bottle would normally sit, but which includes a long tube for drinking.</p>
                                <h3>Helmets</h3>
                                <p>Helmets are designed to prevent death from hitting your head on the payment. They are designed to avoid skull fractures. They can be the difference between life and death. Wear one. With that said, most helmets are not designed to avoid cuncussion. The standards in place only require the helmet cushioning to collapse at a very high linear acceleration. (though what we really talking about is deceleration of your brain matter). They are not designed to deal with rotational acceleration or lower forces, which means they do not do much for avoiding cuncussions. I was in a bike accident last year. I hit a hole and went flying. I was unconscious for a while. The headache grew over the next few days so I had an MRI done. There was no bleeding into the brain. Great. But for the next month or two I was stupid. Really. I could not do complex thinking. I also had serious vertico issues. It took nearly a year before my sense of balance went back to normal. Concussions are no laughing matter.  The governmen'ts CPSC standards required in all helmets haven't been revised as our understanding of these issues has grown, and according to the law, they shouldn't be revised unless such changes can be proven to be cost effective. This is called being kind to business. </p>
                                <p>If you walk into a store and buy just any old helmet, you will be getting something that conforms to the CPSC standard. Within that standard the cheaper helmets are just as good as the more expensive helmets. However a handful of manufacturers are coming out with helmets designed to alleviate cuncussions as well. One system is called MIPS (Multi-directional Impact &shy;Protection System). If you see a helmet claiming to use MIPS, pay extra for it. Virginia Tech has come up with a new system for evaluating helmets. As of July 2018 these are their 5 star rated helmets:</p>
                                <p>Bontrager Ballista MIPS $200</p>
                                <p>Louis Garneau Raid MIPS $120</p>
                                <p>Bell Stratus MIPS $150</p>
                                <p>Specialized Chamonix MIPS $75</p>
                                <p>The web site is: <a href="https://www.beam.vt.edu/helmet/bicycle-helmet-ratings.html" target='_blank' rel="noopener noreferrer">https://www.beam.vt.edu/helmet/bicycle-helmet-ratings.html</a></p>
                                <p>Speaking as one who's been through the nightmare of a nasty concussion, I think it's worth your time to seek something like this out&nbsp;and then be willing to pay more for it. To read more on this click <a href="http://www.bicycling.com/senseless/?cm_mmc=BicyclingNL-_-1313206-_-05302013-_-senseless" target='_blank' rel="noopener noreferrer">here</a>.</p>
                                <h3>Training</h3>
                                <p>Racing is different than going out for a ride with your friends. A normal bike ride includes stopping at red lights, enjoying the view, worrying about cars and speed bumps, talking to your friends, stopping to check the map, grabbing a bite to eat, coasting. A race is pure unadulterated grind. You do not slow down. You hardly ever touch your brakes.  You do not coast. You do not socialize. Social rides do not replicate that singular fixation. So I have a bike hooked into a CompuTrainer and that's what I live on when not in Tri season. I push as hard as I can for as long as I can. I monitor my heartbeat and make sure I'm in the training zone. I keep track of my work output and look for improvement. Sounds like a real grind. On the other hand, I'm in front of a TV screen and last winter I went through all the seasons, of West Wing (amazing show), Rome and various other delights. This winter I've started in on Deadwood. The CompuTrainer is very nice, but very expensive. It allows you to race against computer opponents and adds a certain amount of fun to it all. But primarily what you need is something that allows you to peddle against resistance indoors. There are cheaper devices for holding your bike vertical and creating the resistance. Bike exercise machines are OK, but you won't be in the same position as when you're on a real bike. Bikes should be fitted to your frame and bike exercise machines just aren't. So it's less than ideal.</p>
                                <h3>Intensity</h3>
                                <p>One way or another you have to put in the time. You also need intensity. It's easy to sit on a bike and keep the pedals moving and enjoy the scenery or, if indoors, the show you're watching. But you need periods of really pushing yourself. While I worry about too much intensity at the beginning of your running life, I don't have the same concerns about the bike. It is less stressful on the legs and you are much less likely to hurt yourself when pushing hard. So make moments for intense effort. At least once a week I do an hour of repeats/intervals. I do 5 minutes warm-up, followed by 2.5 minutes all out, followed by 5 minutes easy pace to catch my breath, followed by the 2.5 minutes all out, and so on: hard, easy, hard, easy. That's what will build your power on the bike.</p>
                                <p>You'll read about a recommended cadence of 90 rpm. (Isn't it odd that that's identical to what you should shoot for when running?) Truth is 90 is a rough approximation. Your best rate will probably be somewhere between 80 and 100, it all depends on your muscle fiber makeup and the gear you're in. You can give your nervous system a good juicing by going above your rate for short periods. Can you keep things smooth at 100 rpm? What about 110? Having to concentrate on doing a movement well at high speeds will help build up the myelin. So will doing things really slow while paying total attention.</p>
                                <p>Keep your ankle bent at it's normal standing 90 degrees. And keep your foot relaxed. Don't be curling your toes with the strain of it all.</p>
                                <h3>Bike Skills</h3>
                                <p>For triathlon racing there aren't a lot of skills you have to master. Since you're not in a pack (peloton), it's pretty simple. You do have to know how to hold a straight line. If you're weaving about, you're dangerous to the riders passing you. The straight line becomes a real issue when you're doing something unusual: looking back, reaching down for your water bottle, etc. It helps when you've only got one hand on the handlebars to move the hand closer to the stem. Take curves in the shortest line possible, wide to start and then close to the inside of the curve. Look out for loose gravel. Realize that a painted surface (like the lines on the street) will likely be more slick than regular asphalt. Remember when you're taking a curve to have the inside foot (the side you're leaning toward) up and the outside foot down. This avoids scraping the ground with your pedal. When you're nervous about stability (like on a steep downhill or when braking) move your rear end back in the saddle, moving your weight more over your rear wheel. Know how to brake, practice it. Seventy percent of your braking power is in your front wheel. If you're just trying to slow down a tad, feather the back brake to do it. Use both brakes for emergencies. Practice shifting gears until it's second nature. These are the kinds of skills you need to work at. And most of this can't be practiced on an indoor trainer. You've got to get out there. Oh, and wear your helmet. (Heck, they won't let you race without it.) But seriously, I'd probably be dead right now if I hadn't been wearing a helmet in a recent crash. WEAR A HELMET!</p>
                                <h3>Riding With Others</h3>
                                <p>While you don't need pack riding skills when you're racing, you'll want to get out with some friends or a bike club as a way of enjoying the experience of biking. Have a realistic sense of what speed you can maintain over a long ride and choose a group you'll be comfortable keeping up with. When riding in a peloton with others, people tend to form columns, often two across, with everyone drafting off the person in front of them.
                                    Certain skills become important. </p>
                                <p>Try and stay on a steady line. People behind you are cluing off your behavior. Don't get fixated on the rear tire ahead of you and constantly over-adjust. Instead see the road ahead and ride a fairly straight line.</p>
                                <p>The people in front will make a point of calling out dangers on the road (potholes and such). People behind need to listen and react without undue swerving. If you've got a long enough column pass the info back.</p>
                                <p>People will pull at the front for a while and then drop back to the end of the column. Sooner or later it will be your turn to lead the line. You'll notice that peddling gets more difficult because now it's your turn to break through the wind. The first thing you want to avoid is getting psyched and powering ahead. Notice the speed the group is riding at and maintain that. Stay at the front until you feel like you've done your share and then it's your turn to pull left and let the column ride past you. Join in again at the rear. Your time at the front does not need to be as long as the strongest riders. If you stay too long at the front, you could find yourself too tired to continue an hour later.</p>
                                <p>The problem with all of this is that Tri Bikes are not well suited for the peloton. You don't even have your hands on the brakes. Bike enthusiasts are not real happy when a triathlete shows up showing off his aero position. You're a hazard to them. You either need a road bike for such excursions or you need to go out with some triathlon friends and avoid clumping.</p>
                                <h3>Taking Care Of Your Bike</h3>
                                <p>You need to be able to change a tire. And you need to keep your chain clean. Beyond that, the more you know about bike maintenance the better, but you can survive without it. Buy your bike from a shop you like and cultivate a relationship. Be loyal and they'll be there for you when you're needing an adjustment at the last second. When trying to fix something yourself, you can find all sorts of instructional material on the internet. Do a search on "change a bike tire" and you'll find several videos. A wonderful source worth checking out is <a href="http://bicycletutor.com/" target='_blank' rel="noopener noreferrer">bicycletutor.com</a></p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </Container>
    )
}