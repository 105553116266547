import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import TriathlonIndex from "./TriathlonIndex"

export default function TriStrength() {

    return (
        <Container>
            <Row>
                <TriathlonIndex />
                <Col>
                    <h1>Strength Training</h1>
                    <p>As a youth, I was on the scrawny side. Not much of an athlete. And didn't feel good about myself as a result. Being fit should matter for all of us. But for a boy, it really matters big time. Feeling week feels bad.</p>
                    <p>In my early twenties I pretty much solved my athlete identity problems by becoming a marathon runner. That helped a lot, but there was still some small part of me that wanted to feel powerful. So I became a black-belt Aikidoist. And yet, still, some vague need persisted. I couldn't wear the black-belt to the super&nbsp;market. I suspect it's more fun being Superman than Clark Kent. I felt confident, but not strong.</p>
                    <p>Then somewhere around my fiftieth year, I started lifting weights to improve my triathlon performance. I discovered having muscles is fun. Not only that, but they followed me around wherever I went. Here I am at the supermarket and here are these muscles bulging out in various spots. Cool.</p>
                    <p>No matter what you do, having strength makes it easier. Shooting a basketball? If you're weak, you'll have to heave with all your strength. Add some serious muscle into the mix and you're no longer struggling. Your shot is calm, full of finesse and precision. Muscles make whatever you do easier.</p>
                    <p>Getting stronger is a matter of having a dialogue with your body. You tell it you want to get stronger. You give it some protein to work with and a little time to recover. Your body will build itself up. The dialogue begins with a little pain. If you can do ten pushups, and you do five, you've just told your body. "I sometimes need to do pushups. Please don't remove protein from these muscles." But suppose you do ten pushups and try for one more. Except you can't quite make it. You feel a burning in the muscle from the effort. That leaves an entirely different message in the muscle. You've told the body it needs to get stronger in this area. Eat properly and give those muscles a couple of days off from heavy lifting, and you will end up a shade stronger. Keep at it consistently and you'll have real muscles.</p>
                    <h3>How Many Reps?</h3>
                    <p>Interestlingly, how you respond to exercise is very specific. Doing bicep curls will not make your triceps stronger. Well, I guess that's not a surprise. But how you lift weights affects how you get stronger. Assuming you're lifting a weight that allows you to max out, if you can do  twenty repititions, you will get stronger, but the strength that results will be shifted slightly in the direction of endurance. And the muscle that results won't be quite as large as if you were lifting between eight and twelve repititions of a heavier weight. If you're a body-builder and you want big muscles, that range of eight to twelve repititions seems to be the sweet spot. If you're going to be doing power&nbsp;lifting in the Olympics and you need power, you'll probably practice lifting even heavier weights, but with fewer repititions, somewhere between one and six reps. And according to the people who study such things, your muscles won't be quite as large as a body-builder's, but you'll be a little stronger. I remember when I went back to weight lifting in my sixties, I started out doing higher repetitions with lighter weights in the beginning. My goal was 20 repititions. I wanted to give my body a chance to adjust before attempting the heavier weights. And heck, I'm an endurance athlete. I'd rather skew the results toward endurance rather than bulk. In fact I doubt a seriously muscled body builder will ever win a triathlon. Too much bulk to carry around that won't help their time over distance. So decide what your targeted number of reps will be in the beginning. But I suspect the differences that result between doing many reps with less weight and few reps with more weight are minor. Both create muscle.&nbsp;</p>
                    <h3>Machines vs. Free Weights</h3>
                    <p>The conventional wisdom is that Free Weights are better for you. The advantage is that having to use the muscles while also maintaining balance and moving the weight through space will trigger greater neurological resources. Great. And if you're training&nbsp;for a weightlifting competition, I couldn't agree more. You need to practice what you'll be doing in competition. But if you're not training to be a weightlifter, but to have the strength necessary for other pursuits, then how you made the muscle stronger seems irrelevant to me. I think the machines have a lot to offer. They're very easy to use. They're safe, you won't need a spotter. There's not a lot you have to learn to get started. I use the weight machines and I make no apologies for doing so.</p>
                    <h3>Form</h3>
                    <p>The last thing you want to do is twist about and struggle when lifting weights. Only lift with the relevant muscles. Don't arch your back. Don't try and use your trunk to help out. Don't become desperate. Calmly lift, focused and determined. But don't use contortions to get that last inch of movement. You can easily hurt yourself if you struggle wildly. Focus on your form. Focus on the contraction. It really doesn't matter if you succeed in lifting the weight. What matters is that the muscles tried.</p>
                    <h3>Getting Started</h3>
                    <p>Sit in a machine. Read the instructions. If you have questions, find a trainer and ask them. (If your fascility offers a course in how to use the machines, take it.) Start with what you assume is a light weight. If it's ridiculously light, increase the&nbsp;weight. Find a weight that is easy to do for your first stab at it. Do as many as you can do while still maintaining proper form. Is it more than your targeted number? If so, make a note that next time you'll do the machine at the next weight level available. Be OK in the beginning with working your way up to your ideal weight level. Eventually you may want to do multiple sets, but in the beginning, I would just do one set. That's all you need to trigger change in the beginning. As you get stronger and used to the process, multiple sets may become adviseable. But go easy in the beginning and give yourself a chance to get used to everything&nbsp;without turning it into a mountain you have to climb. Your&nbsp;"will" will get stronger as your body gets stronger. Don't set exhausting goals for yourself in the beginning. Just do something.</p>
                    <h3>The How of Strength Training</h3>
                    <p>The best general advice is to go at a calm slow pace. Don't throw the weight. On the other hand, I can imagine a justification for doing fast hard contractions on a weight machine. When you're running you need a quick contraction if you want to push off quickly and run fast. That power is enhanced by explosive movements. Box jumps come to mind. Have you ever seen someone standing flatfooted in front of a platform and jumping up on to it. Not a bad way to give yourself some explosive power.</p>
                    <h3>Rest</h3>
                    <p>Don't work the same muscles two days in a row. Give yourself at least&nbsp;48 hours between sessions. A body builder who trains every day won't work the same muscle two days in a row. One day may be upper body and the next day might be lower body. </p>
                    <h3>Getting Older</h3>
                    <p>Did you know that people start losing 10% of their strength every year once they hit late middle age? At least, that used to be the conventional wisdom. They had done a study, and that's what the statistics showed. And then someone decided to study elderly athletes. And for that population it just isn't true. I'm nearing 70 now and I'm stronger than I've been for 95% of my life. Stay active, lift some weights, and you can keep yourself fit into your later years. </p>
                </Col>
            </Row>

        </Container>
    )
}