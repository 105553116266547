import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import TriathlonIndex from "./TriathlonIndex"

export default function TriWhy() {

    return (
        <Container>
            <Row>
                <TriathlonIndex />
                <Col>
                    <h1> Why Exercise? </h1>
                    <br></br>
                    <p>Because we evolved in a life of activity and without movement we don't function terribly well. Because biology requires us to move if we are to remain healthy. That's the difference between us and machines. If you use a machine, it wears out. If a biologic system is used well, it gets stronger. Biology adapts to whatever is asked of it. If you begin to run, you will be turned into a runner. But, frankly, it is an ultimately stupid system. Because it works both ways. If you are fit and choose to spend a few months of well-earned rest watching television on the couch, you will be slowly taken apart. What was muscle will be deemed of little use and will be converted to fat, a substance easily maintained and very useful when the next famine rolls around. We are stuck with this. This is how we evolved over millions of years. And we will not evolve in another direction any time soon.</p>
                    <p>Since we evolved in activity, many of the functions which keep us healthy are linked with activity. If we are not active the functions don't happen. People who exercise five times a week take about 45% fewer sick days than people who are sedentary. British workers were surveyed and said they made fewer mistakes, concentrated better and were more productive on days when they exercised in the morning as opposed to days when they didn't. Exercise helps manage high blood pressure, improves bone health, boosts HDL while decreasing triglycerides, promotes better sleep, boosts your energy level and can improve your sex life. The list goes on, and I'm sure you've heard all this before. But it's equally good for your brain. Would you believe college students who exercise at least 20 minutes each day have higher GPAs than there sedentary classmates? Coincidence? I don't think so. Exercise improves your mood and can even effect brain disease. There's a neurotoxin you can inject into the brain of lab mice to create an awfully good impersonation of Parkinson's. However, if the mice have been kept on an exercise regimen, there'll be little or no Parkinson's resulting. Along these lines, a study in the Journal of the American Geriatrics Society reported that women who were active as teenagers were less likely to develop dementia later in life. Exercise is like giving the brain a bath in the fountain of youth.</p>
                    <p>People who exercise just 15 minutes a day live three years longer than people who don't exercise. People who are serious about exercise live between six and seven years longer than their sedentary neighbor, and the quality of those years is much higher. Researchers are even beginning to understand why that's the case. A study at McMaster University in Canada found that vigorous aerobic exercise reduced nearly all the signs of aging in mice genetically programmed to age faster than normal. One of the prime causes of aging is the genetic mutation over time in a cell's replicating mitochondria. Well, mitochondria play a prime role in exercise and their repeated activation helps prevent mutations. There's also the issue of senescent cells. These are cells that have become dysfunctional. They no longer devide and all they do is sit there releasing toxins that damage adjacent cells. The body has mechanisms for removing senescent cells, but they deteriorate as we get older. Exercise boosts the bodies ability to remove such cells. They've managed to remove many of the effects of aging in lab mice where an experimental process was used to remove senescent cells. Someday we may have a vacine that will allow us the same anti-aging benefits. But in the meantime, there is exercise.</p>
                    <p>Plus more exercise is better than a little exercise. If you run more than 35 miles a week, your are 54% less likely to suffer age-related vision loss than if you only cover 10 miles a week. If you go out for at least a 10 mile run once a week you'll be 39% less likely to use high-blood pressure meds and 34% less like to need cholesterol meds than someone who doesn't go over 3 miles each run. Men who burn at least 3,000 exercise calories each week are 83% less likely to have severe erectile dysfunction.</p>
                    <p>A review of 22 studies found that if you work out 2.5 hours a week, you're 19% less likely to die prematurely than if you don't exercise. A separate study found that active people have a 50% lower risk of premature death.</p>
                    <p>Conversely, a sedentary existence can shorten your life. There's a new study in the Journal of the American College of Cardiology that found that "anyone who devotes more than four hours daily on screen-based entertainment such as TV, video games or surfing the web, ups their risk of heart attack and stroke by 113 percent and the risk of death by any cause by nearly 50 percent compared to those who spend less than two hours daily in screen play."</p>
                    <h3>We Evolved as Endurance Animals</h3>
                    <p>.............more to come</p>
                    <h3>Find Something</h3>
                    <p>Which exercise you choose is less important than that you choose something. I'm going to rave about triathlon as a healthy lifestyle. But that's just me. If the rest of these little articles don't win you over, fine. But find something. Find a way to stay active, that revs up your heart and gets you sweating, and make it a part of your life. There's a section of this web site on <a href="../Dance/DanceSocial.html">dance</a>. It's not performance dance but everyday-people dancing like Square Dance, English Country Dancing , Scottish, or Folk. It keeps you moving and if triathlon is just too much for you, consider dance.</p>
                </Col>
            </Row>
        </Container>
    )
}