import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import TriathlonIndex from "./TriathlonIndex"

export default function TriNutrition() {

    return (
        <Container>
            <Row>
                <TriathlonIndex />
                <Col>
                    <h1> Nutrition </h1>
                    <p>I’m going to begin with the story of bread. When humankind first baked bread it was simple, brown and coarse. It was good for us. Over the centuries little about the process changed. Then groceries came upon the land. And they were very good. You could buy all sorts of foods in the same place. They even sold bread, so you didn’t have to bake your own. They did have a problem with the bread. If it wasn't purchased immediately it would get old and stale and even rancid. So the bread makers put the scientists and inventors to work. Wheat has various parts to it. The outside covering, the bran, didn't grind up as smooth as the internal core. It coarsened the flour. Getting rid of the bran before grinding the wheat solved the problem. (They came up with a machine to do it.) The wheat also has a small, very nutritious, top called the wheat germ. Unfortunately, the germ has oil in it. That was causing the rancid flour. Getting rid of the wheat germ before grinding the flour solved the problem. People learned to love the idea of white bread. It seemed so refined and high society. They came up with a method for bleaching the last of the color out of the flour. To help the bread stay “fresh” on the grocery shelves, they found chemicals that would keep the bread from drying out. And thus was born “Wonder Bread.” That was actually a brand name. If memory serves, the prime advertising slogan was “squeezably soft.” No wait, that’s a toilet paper. Maybe it was something about freshness. I can remember the commercials showing the bread being squeezed and bouncing right back, even after weeks on the grocery shelf.</p>
                    <p> The only trouble is, the final product had little nutritional value. All of that had been removed. People were concerned. A law was passed saying that such flour had to have some of the vitamins added back in at the end of the process. That gave the bread companies a new slogan to slap on their labels, “vitamin enriched.”</p>
                    <h3>Micronutrients</h3>
                    <p>Do you see how utterly sad all this is? The vitamins they added back in at the end of the process were just a faint shadow of the original nutritional value of the wheat. All that was left were the calories. This question of the nutritional value versus the number of calories is how we’re going to look at food in this little discussion. We’ll call it nutritional density.&nbsp; So what do we mean by nutrients? Well your body runs on stuff, mostly it burns calories and converts that into activity. It also uses protein to repair muscles and so on. But it also needs what are sometimes called micronutrients: vitamins, minerals, chemicals and agents needed in relatively small quantities. They’re catalysts for the chemical reactions constantly happening in your body to keep things running. You don’t burn through them the way you do calories. But they need to be available in the background for the calories to get burned efficiently and for the body to take care of itself. There’s a long list of these subtle operatives and the list keeps getting longer as scientists understand more and more about how the body functions. It is a much more complex and varied assortment than can be added  with a handful of vitamins to make "enriched" flour. Spinach has a broad supply of these micronutrients with very few calories. Sugar has no micronutrients and lots of calories. Spinach has a wonderful nutritional density. Sugar does not. The food group with the highest nutritional density is vegetables.</p>
                    <h3>The Vegetarian Diet</h3>
                    <p> Now you probably think I’m going to tell you that eating lots of vegetables, some fruit and healthy fats like olive oil and avocado would make for a great diet. Well, actually it would. I can’t think of a better diet than a vegetarian diet done right. I say <em>done right</em> because you actually need to eat veggies. A diet which avoids meat but stocks up on candy and white bread does not qualify. A quality vegetarian diet  means lots of veggies and relatively few carbs. It’s good for the body, the planet and the waistline. You won’t find a lot of fat vegetarians in your travels. But it’s not the avoidance of meats that makes them skinny. It’s avoiding the empty calories. I will mention that I’m not a vegetarian. I just don’t love vegetables enough to do it right. But if you can manage it, it’s a great way to go. (Warning, if you're avoiding dairy, you might need to supplement calcium. And the nonheme iron in plant foods isn't as easily absorbed as the heme iron in meats, either increase your intake of iron rich foods or consider supplementing. But I'm sure you knew all that.) </p>
                    <p> If it sounds like I’m vilifying calories, I don’t mean to. Calories used to be what kept us from starving to death. They’re what we run on. But we’ve always had to work for our calories. We’ve never had them handed to us from a drive-through window. And in such quantity! Never in the course of human history have calories been so plentiful and so cheap. As a result, we have become an increasingly rotund species while starved for essential nutrients. It’s not healthy. But it’s fixable. People can choose low calorie, nutrient dense foods. And if you do, you can eat till you’re full, not have to feel starved, and still manage to lose weight.</p>
                    <h3> The Glycemic Index</h3>
                    <p>The carb chains in grains can get broken down fairly quickly. Turned into sugar they can rush into your system to replenish muscles hungry for more glycogen. The speed at which this happens is called the glycemic index. A low glycemic index means the process takes a while. A high glycemic index means it happens quickly. Foods which flood our system with sugar are a problem because then our blood sugar spikes higher than is healthy. The good news is we come with a regulator to guard against this. When blood sugar spikes the pancreas releases insulin which calms the sugar spike. Great, no problem. Except there is a problem. If you live a life of sugar spikes (something completely new in the experience of homo sapiens), your pancreas works overtime and eventually conks out. It takes years, but you can end up with diabetes. And modern man is experiencing an epidemic of diabetes. But there’s a further problem with this continual release of insulin to control the blood sugar spikes.  Insulin inhibits polysis. Polysis is the process whereby the body burns fat for fuel. Oh really? The body can burn fat? Oh yeah, all the time. If you’re like I was the first time I grasped the concept, your brain is racing right now with the implications. If the body spent it’s days and nights burning fat, could you lose the love handles, the big tummy, the fat thighs, whatever? But when you've got too much sugar in your system, the body wisely inhibits the burning of fat and focuses on burning off the excess sugar.</p>
                    <p> So high glycemic foods should be avoided, right? There’s a moment in a movie regarding the Kona Ironman when there’s a shot of an old veteran in his 80’s scarfing down a baked potato. He chortles “highest glycemic” to explain his choice. A runner in the background looks confused and say, “No, you want low glycemic.” May I say on behalf of us old farts, you do learn a few things over the years. Listen to your elders. When you’re racing, you have very little resources available for processing foods. You want food that can quickly and easily get turned into energy and removed from the gut. At such moments, at ton of low glycemic food could be a disaster.</p>
                    <p>But generally, yes, low glycemic is better for us than high glycemic. The best place I've found for information on the glycemic index is <a href="http://www.glycemicindex.com/" target='_blank' rel="noopener noreferrer">here</a>.</p>
                    <h3>Fats</h3>
                    <p>We use fat to create hormones and build nerve tissue. Some fats are good for you. Some are bad for you. And modern cooking has created some fats that are deadly. From best to worse:</p>
                    <p>Monounsaturated Fats: Great for you. Olive oil, nuts, avocados, canola oil.</p>
                    <p>Polyunsaturated Fats: Almost as good for you. Seeds, omega-3 from fish and flaxseed (which can both be obtained as a pill supplement). While there are health benefits from omega-3, there's also a downside for men. There's a study out in the Journal of the National Cancer Institute showing that men with the highest levels of omega-3 fatty acids had a 43 percent increase in risk for prostate cancer and a 71 percent increase in risk for the high-grade prostate cancer that is likely to be fatal.</p>
                    <p>Saturated Fats: Not so good. This is animal and dairy fat. A little won't kill you, but be aware of overdoing.</p>
                    <p>Trans Fats: Avoid at all costs. Mostly this comes from the way we process foods. If a package has a trans fat value above 0, don't buy it. If the product has been hydrogenated in any way, don't buy it.</p>
                    <h3>The Kitchen Sink</h3>
                    <p>This is going to be a free form recitation of everything I’ve heard (and trusted) about nutrition over the years.</p>
                    <p>Fresh is best. Frozen is almost as good as fresh. Canned is not as good. If you cook vegetables in water, that water you pour down the sink when you’re done is a nutritional gold mine. Those frozen vegetable bags that steam in the microwave are really a great idea. Some vegetables are digested better after cooking. But, in general, the more you process foods, the less nutrition is left when you finish.</p>
                    <p>Dairy and animal fat are not all that good for you. Nonfat dairy and lean meat have a lot of nutritional value and fewer negatives. However I feel compelled to mention that vegetarians have a lower likelihood of developing cancer than meat eaters. This even applies to meat-eaters who are careful to avoid animal fats.&nbsp;And I would really avoid&nbsp;processed meats: bacon, sausage, balogna. Meats preserved with salt, curing, chemicals or nitrates just not what you need.</p>
                    <p>When buying bread look for the ingredient "whole wheat flour." Wonder bread is white, light and easily squeezable. The best breads tend to be the opposite of Wonder bread. However the bread companies will try and fool you. They'll say "Wheat Bread" or "Enriched" on the label. They'll dye the bread brown. They'll have a little bit of whole wheat flour in there but most of the flour will be white flour. Look at the ingredients list. Is "whole wheat flour" the only wheat ingredient? Then you're home free.</p>
                    <p>When you burn bread the result contains carcinogens. If you burn a piece of toast, throw it out. If you really want to get neurotic, bread crust has modest amounts of&nbsp;the same carcinogens. Maybe you were doing your kids a favor when you cut off the crust. When toasting bread, do it lightly.</p>
                    <p>When people say that iceberg lettuce is nutritionally useless, they're being hysterical. HOWEVER, if you get the chance, study the nutritional difference between green leaf lettuce and iceberg lettuce. It's huge. There are so many forms of lettuce that are so much better for you than iceberg. And spinach is even better for you than green-leaf lettuce. My girlfriend always ordered spinach on her half of the pizzas we order. So I would often get a little overflow on some of my pieces. I found this slightly annoying, but decided it wouldn't kill me. Except, to my surprise, it wasn't so bad. What little taste it adds is almost sweet. I finally admitted I rather liked it. Now we get the whole pizza with spinach.</p>
                    <p>Fiber is good for you. When eating something that lacks fiber (like your typical pizza) you can make up for that by popping an&nbsp;oat bran tablet.</p>
                    <p>Don't eat fried stuff. It's just not good for you. The skin from a fried chicken is probably the deadliest thing&nbsp;anyone eats these days.</p>
                    <p>If you're going to eat something you know isn't good for you, a little moderation is called for. I enjoy french fries. I'm hoping a few french fries won't have a significant affect on my health. So if I can arrange to share an order with friends, I do. Otherwise, I skip them.</p>
                    <p>I know folks who would never eat white bread and yet they seem to consider pasta health food. I'm not real clear what the difference is. The standard white pasta you buy in the grocery store is just carbohydrate without a huge amount of nutritional value. They do sell whole wheat pasta, but I must say I haven't learned to love it.</p>
                    <p>Peanut butter naturally separates into two levels: the protein from the peanuts and the oil. To keep this from happening manufacturers typically hydrogenate the peanut butter, turning it into something very bad for your arteries. (This is one of those trans fats we talked about earlier.) Don't buy hydrogenated anything. Don't buy partially-hydrogenated anything. Get your peanut butter unhydrogenated and mix it yourself with a knife (yeah, this is a drag, too bad). Once you've got it mixed up, keep it in the fridge to avoid it separating&nbsp;again.</p>
                    <p>Butter is a less than great fat. The margarine that replaced it for the healthy conscious among us was even&nbsp;worse. That's because, while they started out with ingredients that might have been better than butter, they felt they needed to hydrogenate the oils to keep them a solid. These days many of the butter substitutes are doing better. But check to make sure they have no trans fats and are not hydrogenated in any way.</p>
                    <p>Organic is better for you but more expensive. If you have unlimited funds, just do it. If you're worried about the money, pick and choose. If you're going to discard the skin, you'll be throwing out most of the toxins: bananas, avocados, grapefruit, you get the idea. If you'll be eating the skin, go organic. Non-organic strawberries have a relatively high level of pesticides to them. Time to buy organic.</p>
                    <p>Sometimes I just try and do the right thing. If you believe animals shouldn't be kept in horrible conditions, then free-range eggs seem worth an extra couple of bucks.</p>
                    <h3>Chocolate</h3>
                    <p> Chocolate deserves special mention because, well, it’s chocolate. It’s actually a healthy fat, and it’s full of antioxident flavonoids. This can be a health food, people. However natural chocolate is bitter. So they add sugar. Add a little sugar and you’ve got dark chocolate. Add some more and you’ve got regular chocolate. So dark chocolate is obviously better for us. The percentage of cocoa will sometimes be printed on the package. Avoid anything below 35%, but the higher the percentage you can enjoy, the more flavonoids you'll get. With regular chocolate they’ve added enough sugar to move it into the questionable category. Also avoid the "Dutch Process Chocolate" that you'll sometimes find in hot chocolate or chocolate ice cream. So check for "cocoa processed with alkali" in the ingredient list and avoid it when found. Because it means that most of the antioxidents will have been destroyed. Not good. Just understand that chocolate is calorie dense, and consume cautiously. However if you’re a triathlete burning through a couple thousand extra calories a day, you’re allowed a few extra calories. At the moment, I'm recovering from a bike accident and not able to exercise. Chocolate has sadly been dropped from the diet. And I've actually reached the point where the cravings have passed.</p>
                </Col>
            </Row>

        </Container>
    )
}