import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import TriathlonIndex from "./TriathlonIndex"

export default function TriWeightLoss() {

    return (
        <Container>
            <Row>
                <TriathlonIndex />
                <Col>
                    <h1> Losing Weight </h1>
                    <p>I was at a party once. The hostess said, "Here, have some cake." I said, "No thanks, I'm watching my weight." She had a fit, "But look at you! You don't have to watch your weight." She looked astonished. She clearly thought I'd lost my mind. I wanted to say to her, "But, Lady, I look like this BECAUSE I watch my weight." There are very few people who can eat whatever they like and stay slim. Nearly everyone fights the battle of the bulge.  The only question is where we fight it. Some people are a hundred pounds heavier than their ideal weight, and they fight not to get fatter. I fight it awfully close to where I should be. But I do fight the same battle. I may gain a few pounds once in a while. I just don't tend to let it get out of hand.</p>
                    <h3>Getting Out Of Control</h3>
                    <p>I did once. I was finishing up the second music album, working long hours and main-lining chocolate. Buy the time the album was off to the duplicator I'd put on 20 pounds. I had an interesting experience back then. I'd eaten a fair helping of chocolate and was pleasantly full. Part of me wanted more chocolate. <em>No</em>, I decided, <em>You've had enough.</em> I then watched myself get up, walk over to the cupboard and get out some more chocolate. All the time I'm telling myself not to do it. It was an out-of-body experience. My decision making process was no longer running the show. I assume some who are overweight have similar experiences. You're out of control, and no amount of berating yourself seems to help. I'm not going to tell you this is an easy addiction to beat. But it's worth the effort.</p>
                    <h3>The Losing Battle</h3>
                    <p>As long as this is a battle about deprivation, you're likely to lose it. If you starve yourself and force yourself to do things you hate, you're not going to accomplish permanent weight gain. You almost have to stop thinking about your weight and start thinking about getting healthy. What can you do to eat healthy foods, get some exercise and still enjoy life? Can you gradually shift your focus from sugary snacks to vegetables? Can you learn about nutrition and start making changes in your diet? Can you find a form of exercise you actually enjoy so it doesn't feel like a chore? And are you willing to make the changes permanent so they become who you are? And are you willing to live the rest of your life like that, living healthy? Well, if you do, over the years, and I do mean years, the weight will come off.</p>
                    <h3>Diets</h3>
                    <p>Diets don't tend to work, not because you can't lose weight with them, but because they're <em>diets</em>, they're special. You lose the weight, and you're done with the diet, and the next thing you know you're getting larger again. You have to change your eating habits to something healthy that you can live with the rest of your life. Eat a good diet with veggies and fruits and protein and very little empty carbs, and you'll probably lose weight.</p>
                    <h3>Genetics </h3>
                    <p>Yes, on rare occasion, genetics is part of the problem. There is a part of chromosome 16 that researchers have found is sometimes duplicated in certain individuals. These people find it rediculously easy to stay slim. They tend to be underweight. But they've also found instances where individuals lack that part of the chromosome entirely. When those genes are missing your chances of being morbidly obese go up big time. But even if you're lacking that genome, that doesn't change anything. Being obese is still very bad for you. You still need to make the effort and keep your weight under control. It does mean it will be more difficult for you than for others. And yes, it's not fair. But we all have some sort of cross to bear in our lives, something where we don't have an easy time of it. This one's yours. Keep in mind that a meta-analysis of 45 studies covering 218,166 adults found that those with the obesity gene issue were much less likely to become obese if they exercised regularly. I will talk about the limits to what exercise can do momentarily. But if you've got obesity in your genes, exerercise is part of the solution.</p>
                    <h3>Other Factors</h3>
                    <p>The unfairness continues. If your mom was heavily exposed to Polycyclic Aromatic Hydrocarbons while she was pregnant with you, your body <em>might</em> (further research needed) find it harder to release lipids from your fat cells. Other pollutants including BPA may make weight loss difficult. Antibiotics can throw off the delicate mix of bacteria in your gut and that can interfere with weight loss. In fact if you were given antibiotics in your first six months of life you will be more likely to be overwight as a toddler. Even lack of sleep can lead to weight gain. So life is unfair. There really are people who eat well and exercise and can't get their weight down to where they want&nbsp;it. Which is a damn shame, and not fair, but at least (despite the extra weight they can't shed) they're fit and healthy.</p>
                    <h3>What's Willpower Got To Do With It?</h3>
                    <p>Well, everything. I know you're tired of people thinking anyone who's fat has no willpower. You feel like you work hard to make good decisions and you're still overweight. You've got a choice here. You can either take the "it's not my fault" route and feel helpless. Or you can take responsibility for the way you look and gain control over your life. You know those muscle-bound guys. That's willpower on display. They had to go out and lift the weights. They had to learn to love the burn (what others would describe as pain). You earn muscles. A triathlete looks the way he does because of the life he lives. We have the option in this time and place to live very easy lives. We can eat whatever we want and watch lots of television. I happen to love television. I don't think it's a waste of time. There's all sorts of quality viewing available these days. And it's easiest to choose the easy way. To eat all the tempting goodies we have available to us. And to spend our days pampered and entertained. Every once in a while I'm sitting in front of the TV with a bowl of ice cream, and it's wonderful. And if you do that consistently your body will reflect those choices. Staying healthy involves an exercise of the will. You have to choose the harder path. And then walk it. But what I've learned is that your willpower is like a muscle, the more you use it, the stronger it gets. The more you make the hard decisions, the easier it is to make them. In another sense it really does get easier. You actually get to the point where running 6 miles is kinda fun, your adventure for the day. You enjoy slipping through the water and miss it when that's not available. You can even learn to love healthy foods and find that you don't miss the old addictions. But getting to that point is an uphill slog.</p>
                    <h3>Exercise: Uses And Limits</h3>
                    <p>My solution to my excess weight was to go back to being a triathlete. I'd spent a number of years unable to run because of a torn toe tendon and the surgery to fix it. Around this time I had finally reached the point where I was able to walk on it without pain and could go back to being an athlete. The weight came off very gradually over the next year, just because I was working out. The best thing about exercise is that once you've invested the time in getting fit, it's much easier to make good decisions about what you eat. You just hate the idea of undoing all your hard work with an unhealthy diet. But exercise has the drawback of increasing your appetite. You can lose weight this way, but you have to do a lot of exercise and the weight only comes off gradually. Ultimately, I think it's what you eat that decides your weight. Everyone should exercise just because we need exercise to be truly healthy. But if you're relying on exercise to burn the fat off, there are all sorts of reasons it may not work. You may burn 300 calories on your run, and then burn 300 calories less the rest of the day because you're tired from the run. You may be hungrier because of the exercise and eat an extra 400 calories. You may reward yourself with a 500 calorie donut. It is far easier to simply not eat the candy than to exercise it away. If you're trying to lose weight, what really matters is what you eat.</p>
                    <h3>3500 Calories = 1 Pound</h3>
                    <p>The basic reality of life is weight represents calories, stored or burned. If you take in 3500 more calories than you burn, you'll gain a pound. If you burn 3500 more calories than you take in, you'll lose a pound. Think of it this way. If you burn just 100 more calories than you take in each day, after a year you'll have lost ten pounds. Except it doesn't quite work that way. As you east less, you're expending fewer calories processing food, so your calorie needs diminish. Your metabolism changes. You tend to get less benefit from all you do to lose weight than you should. It's rotten. It's unfair. It's just the way it is. More on metabolism in a moment.</p>
                    <h3>Eat Less</h3>
                    <p>I've had family members who were overweight. And it wasn't a mystery why they couldn't keep the weight off. They'd eat twice as much at a meal as needed. If you're going back for seconds, you're in trouble. One trick that helps is smaller plates. Don't start out with a big plate and feel you have to fill it up. Use a smaller plate and smaller portions. One of the things I've noticed about foods is that the first bite is the best. If you want to taste something you know is wicked, I would say that's not the end of the world. But can you really enjoy that one bite? Truly savor it? And then be done with it? On the other hand, if there's something sinful you rely on for satisfaction, I think most people are better off just not starting. After you've been off the craving for a while, the craving eases and it's not so hard living without it.</p>
                    <h3>Metabolism</h3>
                    <p>Now all of this is equations, and yet there's a variable that can frustrate the straight science. And that's metabolism. Metabolism is how carefully the body burns calories. Usually this works in your favor. If you turn fat into muscle, one theory holds that you then burn more calories in your sleep than you did before. Muscle needs energy and nutrients to stay alive. It requires calories be burned just to keep it around. Fat, not so much, it can just sit there&nbsp;and your body doesn't have to expend a&nbsp;lot of effort in keeping it viable. One of the frustrating things about physiology is that your body knows this. If you've stopped exercising and are spending your days sitting on the couch watching TV, your body knows you don't need the muscle&nbsp;any more and that it's expending a great deal of precious energy keeping that muscle healthy. <em>Oh, heavens, what shall I do when I have my next experience of famine?</em> So the body will begin to break down muscle and convert that protein to fat for long term storage. This saves calories in the short run and provides backup calories for when they're needed. If your body is getting too few calories with too few of the nutrients it deems essential, this same famine avoidance can kick in. You will find ways to burn fewer calories during the day. A scientist studying you would say that your metabolism decreased. Sometimes that's what happens to people on a diet, and they'll find themselves frustrated because their weight loss has plateaued. At such times adding a couple hundred calories of protein and veggies each day may allow the body to get out of the starvation mind-set, ramp up the metabolism, and let go of some of that fat it keeps around for emergencies.</p>
                    <h3>Keeping A Journal</h3>
                    <p>I've had a year of reduced exercise lately and gained maybe ten pounds because I wasn't burning as many calories as I used to, and I didn't have enough sense to change my diet to match. So six months ago I started cutting back on the calories to lose the weight. Except after five months I hadn't lost even a pound. I've resorted to using an app called MyFitnessPal. I have things I don't love about how the program was designed, but the reality is it works. It keeps track of how many calories you burn and how many you take in. It gives you a goal for the day, and if you're willing to stick to it, you'll lose weight. If you don't want to use an app, just keep track of how many calories you burn and how many you eat. Keeping a journal may surprise you. I'd done some rough calculations about calories before I started really counting, but I was kidding myself. It's easy to take in more than you realize. By being rigorous about it I'm finally losing the weight.</p>
                    <h3>Body Image</h3>
                    <p>Women's fitness magazines tend to feature a lot of models with descreet amounts of sweat sprayed on them by the photographer. I would dump such images and focus on real athletes, one's with real muscles and hard-one fitness. Don't worry about how you look in a bikini, but find your way to your athletic self. Put your focus on feeling powerful and go for it. Give up trying to look good and focus on feeling good about who you're becoming.</p>
                    <h3>So here it is in a nutshell:</h3>
                    <p>Eat nutrient dense foods. Avoid the foods you know are bad for you. This is easy to say and harder to do. It's almost impossible to do overnight. But can you add one food you know is good for you each week? Hold your nose and your biases and get it down a few times. If you still hate it, you tried. But maybe it will start to seem OK. And can you give up one bad food each week. In time your diet would change. Vegetables have such a high nutritional density (which means lots of nutrients and minimal calories) that you could probably stuff yourself with veggies and still lose weight.</p>
                    <p>Pay attention to what you're eating. Don't watch television with an open bag of chips in front of you. Chew your food thoroughly. Really enjoy it and savor the food.</p>
                    <p>Don't stuff yourself. Don't make up a huge plate of food. Don't go back for seconds. Learn what a sensible portion really is. Become an expert on calories. Actually know how many calories are in a chicken breast. Figure it all out for the foods you eat and then keep track of what you eat each day. Get neurotic about it for a little while. Find out if you're kidding yourself. People who write down what they've eaten and the calories consumed after every meal lose twice the weight of those who don't.</p>
                    <p>Eat on a regular basis, and make sure you eat breakfast. Getting to the point where you're famished is dangerous. You're then liable to overeat.</p>
                    <p>Minimize high glycemic foods. Cut back on the carbs. Include healthy fats.</p>
                    <p>Eating out in restaurants with friends is a problem. You'll eat more than you think you're eating. Lots more. Good fun company; food prepared by people who want you to love what they've fixed and could care less about your weight; it's a formula for disaster. One of the first things they tell students at chef school is, <em>what people secretly like is sugar and fat, give it to them</em>.</p>
                    <p> I'm going to say it again, what and how much you eat is the most important issue. Exercise is essential to a healthy life, and it will help with weight loss. But the primary battle ground needs to be the quality and quantity of the foods you eat.</p>
                </Col>
            </Row>

        </Container>
    )
}